import { defineStore } from 'pinia'
import axios from 'axios'
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";
import GIF from "@/modules/gif";

export const imageStore = defineStore('images', {
    state: () => ({
        files: [{}],
        focus: [{}],
        presets: [],
        name: '',
        presetID: -1,
        selected: [],
        deleteArray: [],
        loaded: false
    }),
    actions: {
        /**
         * load images from server
         */
        loadImages(){
            return new Promise((resolve, reject) => {
                const _this = this
                const auth = authStore()
                const config = configStore()
                const fD = new FormData()
                fD.append('sessionID', auth.sessionID)
                axios.post(config.projectURL + 'dashboard/loadImages', fD)
                    .then(response => {
                        if(response.status === 250){
                            _this.loaded = true
                            _this.files = []
                            for(let key in response.data.images){
                                    if(response.data.focus[response.data.images[key].path] != undefined)_this.files.push(response.data.images[key])
                            }
                            _this.focus = response.data.focus
                            _this.presets = response.data.presets
                            for(let key in this.files){
                                _this.loadBinaryImage(key, 'small')
                                    .then(result => {

                                    })
                                    .catch(e => {
                                        console.log(e)
                                    })
                            }
                            resolve()
                        }else{
                            reject(Error)
                        }
                    })
                    .catch(() => {
                        reject(Error)
                    })
            })
        },
        /**
         * delete image from server
         */
        deleteImage(){
            const _this = this
            return new Promise((resolve, reject) => {
                const auth = authStore()
                const config = configStore()
                const fD = new FormData()
                const index = this.currentIndex
                fD.append('sessionID', auth.sessionID)
                fD.append('name', JSON.stringify([this.currentImage.path]))
                axios.post(config.projectURL + 'dashboard/deleteImages', fD)
                    .then(response => {
                        if(response.status === 250){
                            _this.files = response.data.images
                            _this.focus = response.data.focus
                            for(let key in this.files){
                                _this.loadBinaryImage(key, 'small')
                                    .then(result => {

                                    })
                                    .catch(e => {
                                        console.log(e)
                                    })
                            }
                            if(index >= _this.files.length && index > 0){
                                _this.name = _this.files[index - 1].path
                            }else if(_this.files.length > 0){
                                _this.name = _this.files[index].path
                            }
                        }
                    })
                    .finally(() => {
                        resolve()
                    })
            })
        },
        /**
         * load binary data, return image data
         * @param src
         * @param size
         * @returns {Promise<unknown>}
         */
        loadBinaryImage(index, size){
            const _this = this
            return new Promise((resolve, reject) => {
                if(index < 0) {
                    resolve(size)
                }else{
                    try{
                        if(size === 'small' && _this.files[index].binarySmall) resolve(_this.files[index].binarySmall)
                        else if(_this.files[index].binaryBig) resolve(_this.files[index].binaryBig)
                        const auth = authStore()
                        const config = configStore()

                        const fD = new FormData()
                        fD.append('sessionID', auth.sessionID)
                        fD.append('size', size)
                        fD.append('src', _this.files[index].path)
                        axios.post(config.projectURL + 'dashboard/getImage', fD, {
                            responseType: "blob"
                        })
                            .then(response => {
                                const reader = new FileReader()
                                reader.onload = e => {
                                    size === 'small' ? _this.files[index].binarySmall = e.target.result : _this.files[index].binaryBig = e.target.result;
                                    resolve(e.target.result)
                                }
                                reader.onerror = (function (self) {
                                    return function (e) {
                                        reject(e)
                                    }
                                })(this);
                                reader.readAsDataURL(new Blob([response.data]))
                            })
                            .catch(e => {
                                console.log(e)
                                reject(e)
                            })
                    }catch (e) {
                        reject(e)
                    }
                }
            })
        },
        /**
         * get index of image
         * @param name
         */
        getIndexOfImage(name){
            return this.files.map(e => e.path).indexOf(name)
        },
        /**
         * change index
         * @param number number
         */
        number(number){
            try {
                this.name = this.files[this.currentIndex + number].path
            }catch (e) {
                this.name = this.files[0].path
            }
        },

        /**
         * get Preset by ID
         * @param id
         */
        presetsByID(id){
            return this.presets[this.presets.map((e) => e.id).indexOf(id)] ?? null
        },

        /**
         * convert canvas to gif blob
         * @param canvas element
         * @returns {Promise<unknown>}
         */
        toGifBlob(canvas){
            return new Promise((resolve, reject) => {
                let gif = new GIF({
                    workers: 2,
                    quality: 2,
                    workerScript: '/assets/js/gif.worker.js'
                });
                gif.addFrame(canvas, {delay: 200});
                gif.on('finished', blob => {
                    console.log(blob)
                    resolve(blob)
                });

                gif.render();
            })
        }
    },
    getters: {
        /**
         * return current index
         * @returns {*}
         */
        currentIndex()
        {
            return this.files.map((e) => e.path).indexOf(this.name)
        },
        /**
         * get current image
         * @returns {null|*}
         */
        currentImage(){
            try {
                return this.files[this.currentIndex]
            }catch (e) {
                return this.files[0]
            }
        },
        /**
         * get current focus
         * @returns {null|*}
         */
        currentFocus(){
            try {
                return this.focus[this.name] ?? {}
            }catch (e) {
                return {}
            }
        },
    }
})