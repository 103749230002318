HTMLCanvasElement.prototype.renderImageFull = function(src, data, pattern, focus, lock){
    return new Promise((resolve, reject) => {
        var ctx = this.getContext('2d');
        var img = new Image();
        img.onload = function(){

            pattern.width = parseInt(pattern.width)
            pattern.height = parseInt(pattern.height)

            ctx.clearRect(0, 0, pattern.width, pattern.height)
            let factor
            let sx
            let sy
            let sWidth
            let sHeight
            if(focus.width > 0 && focus.height > 0) { // Ist ein Fokus gesetzt? Sollte immer der Fall sein eigentlich...
                factor = (data.naturalWidth / data.naturalHeight < pattern.width / pattern.height) ? data.naturalWidth / pattern.width : data.naturalHeight / pattern.height; // Faktor abhängig von den Seitenverhältnissen
                const maxHeight = pattern.height * factor; //maximale Breite der Ausgabe
                const maxWidth = pattern.width * factor; //maximale Höhe der Ausgabe
                if (maxWidth < focus.width) { //falls die maximale Breite kleiner ist als die Breite des Fokusbereichs
                    sx = focus.x + (focus.width - maxWidth) / 2; //sx berechnet sich aus (der Breite des Fokusbereichs minus der maximalen Breite) geteilt durch 2 plus dem horizontalen Versatz
                    sy = 0; //sy bleibt null, da die Ausgabe die komplette Höhe belegt
                }else if(maxHeight < focus.height){ //falls die maximale Höhe kleiner ist als die Höhe des Fokusbereichs
                    sx = 0; //sx bleibt null, da die Ausgabe die komplette Breite belegt
                    sy = focus.y + (focus.height - maxHeight) / 2; //sy berechnet sich aus (der Höhe des Fokusbereichs minus der maximalen Höhe) geteilt durch 2 plus dem vertikalen Versatz
                }else{
                    if(focus.width / focus.height < pattern.width / pattern.height){ //vergleiche Seitenverhältnisse des Fokusbereichs und der Ausgabe
                        factor = focus.height / pattern.height
                        sx = focus.x - (pattern.width * factor - focus.width)/2 //sx entspricht der horizontale Versatz des Fokusbereichs minus der Hälfte der Differenz der Ausgabenbreite multipliziert mit dem Factor minus der Breite des Fokusbereichs
                        sy = focus.y //sy entspricht dem vertikalen Versatz des Fokusbereichs
                    }else{
                        factor = focus.width / pattern.width
                        sx = focus.x //sx entspricht dem horizontalen Versatz des Fokusbereichs
                        sy = focus.y - (pattern.height * factor - focus.height)/2 //sy entspricht der vertikale Versatz des Fokusbereichs minus der Hälfte der Differenz der Ausgabenhöhe multipliziert mit dem Factor minus der Höhe des Fokusbereichs
                    }
                }
            }else{ //wenn kein Fokus gelegt wurde, stets so zuschneiden, dass die Ausgabe innerhalb des Bildes liegt.
                if(pattern.width / pattern.height < data.naturalWidth / data.naturalHeight){
                    factor = data.naturalHeight / pattern.height
                    sx = (data.naturalWidth - pattern.width * factor) / 2
                    sy = 0
                }else{
                    factor = data.naturalWidth / pattern.width
                    sx = 0
                    sy = (data.naturalHeight - pattern.height * factor) / 2
                }
            }
            sWidth = pattern.width * factor //Breite des Quellauschnitts
            sHeight = pattern.height * factor //Höhe des Quellauschnitts
            if(sx + sWidth > data.naturalWidth) sx = sx - (sx + sWidth - data.naturalWidth); //falls die Ausgabe über das Bild horizontal geschoben wurde, sx um diesen Betrag verringern
            if(sy + sHeight > data.naturalHeight) sy = sy - (sy + sHeight - data.naturalHeight); //falls die Ausgabe über das Bild vertikal geschoben wurde, sy um diesen Betrag verringern
            if(sx < 0) sx = 0 //sx darf nicht negativ werden
            if(sy < 0) sy = 0 //sy darf nicht negativ werden

            const overlay = new Image();

            //drawing of the test image - img1
            overlay.onload = function () {
                ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, pattern.width, pattern.height) // 1: Bild 2-5: (x, y, width, height) der Quelle 6-9 (x, y, width, height) des Ziels
                if(lock) {
                    ctx.fillStyle = ctx.createPattern(overlay, 'repeat');
                    ctx.fillRect(0, 0, pattern.width, pattern.height);
                }
                resolve()
            };
            overlay.src = '/assets/images/overlay.png';
        }
        img.onerror = function (e){
            //console.log(e)
        }
        img.src = src;
    })
};