<template>
  <main style="height: calc(100vh - 44px); display: flex; justify-content: center; align-items: center;">
    <section class="login" v-if="page === 'login'">
      <div class="content-500">
        <div class="t-center pad-16">
          <img src="assets/images/LogIn-Kreis-petrol.svg" class="responsive-img width-100" >
        </div>
        <div class="t-center pad-32" style="margin-top: -32px;">
          <h1 class="f-normal">
            Willkommen zurück!
          </h1>
          <h2>Logge dich ein</h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input type="email" name="email" placeholder="E-Mail Adresse" required @keydown.enter="login" v-model="email">
          </div>
          <div class="field simple pad-8">
            <input id="loginPassword" type="password" name="password" placeholder="Passwort" required @keydown.enter="login" v-model="password">
            <div id="showPassword" @click="showPassword()">
              <svg id="showPasswordIcon" height="20px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/></svg>
              <svg id="hidePasswordIcon" height="20px" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/></svg>
            </div>
            <div class="grid-12 loginmenu">
              <div class="col-sp-6 row-sp-12 t-left">
                <div class="rememberme field simple">
                  <input type="checkbox" name="logincookie" v-model="cookie">
                  <span>Angemeldet bleiben</span>
                </div>
              </div>
              <div class="col-sp-6 row-sp-12 t-right">
                <div class="forgotpassword">
                  <a @click="page = 'forgot'" class="color-black">
                    Passwort vergessen?
                  </a>
                </div>
              </div>
            </div>
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="loginStatus === 'fail' || loginStatus === 'user'">
              Login fehlgeschlagen. Bitte überprüfe deine Angaben.
            </p>
            <p class="feedbackmessage t-center pad-8 color-orange" v-else-if="loginStatus === 'email'">
              Login fehlgeschlagen. Bitte bestätige zuerst deine E-Mail-Adresse.<br>
              <span @click="resendStartEmail" class="color-black" style="text-decoration: underline; cursor: pointer;">E-Mail zur Verifizierung senden</span>
            </p>
            <p class="feedbackmessage t-center pad-8 color-orange" v-else-if="loginStatus === 'resend'">
              E-Mail versendet. Bitte überprüfe dein Postfach.
            </p>
          </div>
        </div>
        <div class="t-center pad-16">
          <button id="LoginButton" @click="login" class="button bgcolor-petrol color-white circular">
            Anmelden
          </button>
          <!--<div class="divider"
               v-if="fidoCheck">
            oder
          </div>
          <button
              id="LoginButton"
              @click="fidoLogin"
              class="button bgcolor-petrol color-white circular"
              v-if="fidoCheck"
          >
            FIDO-Login
          </button>-->
          <div class="divider">
            oder
          </div>
          <router-link to="/register/1" id="RegisterButton" type="submit" class="button bgcolor-mint color-petrol circular">
            Account erstellen
          </router-link>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'forgot'">
      <div class="content-500">
        <div class="t-center pad-16">
          <img src="assets/images/LogIn-Kreis-petrol.svg" class="responsive-img width-150">
        </div>
        <div class="t-center pad-32">
          <h1 class="f-normal">
            Passwort vergessen?
          </h1>
          <h2>Bitte gib deine E-Mail-Adresse ein</h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input type="email" name="email" placeholder="E-Mail-Adresse" required v-model="email" @input="forgotStatus = ''">
          </div>
        </div>
        <p class="feedbackmessage t-center pad-8 color-petrol" v-if="forgotStatus === 'ok'">
          Sofern dein Account mit dieser E-Mail verbunden ist, erhältst du eine E-Mail.
        </p>
        <div class="t-center pad-16 width-300">
          <button @click="forgotPassword" class="button inverted fluid bgcolor-black circular" :class="{'disabled-inverted': forgotStatus !== ''}" :disabled="forgotStatus !== ''">
            Passwort zurücksetzen
          </button>
          <div class="divider">
            oder
          </div>
          <button type="button" class="button bgcolor-mint fluid color-petrol circular" @click="page = 'login'">
            Zurück
          </button>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'resetpassword'">
      <div class="content-500">
        <div class="t-center pad-32">
          <h1 class="f-normal">
            Neues Passwort erstellen
          </h1>
          <h2>Bitte gib dein neues Passwort ein:</h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input id="loginPassword3" type="password" name="password" placeholder="Passwort" required v-model="pw1">
            <div id="showPassword2" @click="showPassword2()">
              <svg id="showPasswordIcon2" height="20px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/></svg>
              <svg id="hidePasswordIcon2" height="20px" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/></svg>
            </div>
          </div>
          <div class="field simple pad-8">
            <input id="loginPassword4" type="password" name="password2" placeholder="Passwort wiederholen" required v-model="pw2">
          </div>
        </div>
        <p class="feedbackmessage t-center pad-8 color-petrol" v-if="resetStatus === 'ok'">
          Dein Passwort wurde erfolgreich geändert.
        </p>
        <p class="feedbackmessage t-center pad-8 color-orange" v-if="!passwordCheck && pw2.length > 0">
          Die Passwörter stimmen nicht überein oder sind nicht mindestens 8 Zeichen lang.
        </p>
        <p class="feedbackmessage t-center pad-8 color-orange" v-if="resetStatus === 'invalid'">
          Der Link ist abgelaufen. Bitte fordere einen neuen an.
        </p>
        <p class="feedbackmessage t-center pad-8 color-orange" v-if="resetStatus === 'password'">
          Ups, da lief was schief.
        </p>
        <div class="t-center pad-16" v-if="resetStatus !== 'ok'">
          <button @click="resetPassword" class="button inverted bgcolor-black circular" :disabled="!passwordCheck">
            Passwort speichern
          </button>
        </div>
        <div class="t-center pad-16" v-else>
          <button @click="page = 'login'" class="button inverted bgcolor-black circular">
            zum Login
          </button>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'email'">
      <div class="content-500">
        <div class="t-center pad-32">
          <h1 class="f-normal">
            E-Mail-Adresse
          </h1>
          <h2>{{ emailStatus }}</h2>
        </div>
        <div class="t-center pad-16">
          <button @click="page = 'login'" class="button inverted bgcolor-black circular">
            zum Login
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Login",
  title: "Login",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      page: this.$route.query.page ?? 'login',
      token: this.$route.query.resetkey,
      cookie: false,
      email: '',
      password: '',
      pw1: '',
      pw2: '',
      loginStatus: '',
      forgotStatus: '',
      resetStatus: '',
      emailStatus: this.$route.query.emailstatus == 1 ? 'E-Mail-Adresse bestätigt' : 'E-Mail-Adresse konnte nicht bestätigt werden',
      //fidoCheck: navigator.credentials && navigator.credentials.create,
    }
  },
  computed: {
    passwordCheck() {
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  mounted() {
    const _this = this
    this.config.loading = false
  },
  methods: {
    login() {
      const _this = this
      this.auth.login(this.email, this.password, this.cookie)
          .then((response) => {
            if (response === 250) {
              _this.loginStatus = 'ok'
              _this.$router.push('Dashboard')
            } else if (response === 251) {
              _this.loginStatus = 'user'
            } else if (response === 252) {
              _this.loginStatus = 'email'
            }
          })
          .catch(() => {
            _this.loginStatus = 'fail'
          })
    },/*
    fidoLogin(){
      const _this = this
      this.auth.fidoLogin().then(() => _this.$router.push('/dashboard')).catch(e => {})
    },*/
    //reset password
    forgotPassword() {
      const _this = this
      this.forgotStatus = 'sending'
      this.auth.resetPassword(this.email)
          .then((response) => {
            if (response === 250) {
              _this.forgotStatus = 'ok'
            } else if (response === 251) {
              _this.forgotStatus = 'email'
            } else if (response === 252) {
              _this.forgotStatus = 'fail'
            }
          })
          .catch(() => {
            _this.forgotStatus = 'fail'
          })
    },
    //restore password
    resetPassword() {
      const _this = this
      this.auth.restorePassword(this.token, this.pw1)
          .then((response) => {
            if (response === 250) {
              _this.resetStatus = 'ok'
            } else if (response === 251) {
              _this.resetStatus = 'invalid'
            } else if (response === 252) {
              _this.resetStatus = 'password'
            }
          })
          .catch(() => {
            _this.resetStatus = 'password'
          })
    },
    resendStartEmail() {
      const _this = this
      const fD = new FormData()
      fD.append('email', this.email)
      this.axios.post('login/resendStartEmail', fD)
          .then(r => {
            console.log(r)
            if (r.status === 250) _this.loginStatus = 'resend'
          })
          .catch(e => {
            console.log(e)
          })
    },
    showPassword() {
      var showPasswordIcon = document.getElementById('showPasswordIcon');
      var hidePasswordIcon = document.getElementById('hidePasswordIcon');
      var loginPassword = document.getElementById('loginPassword');
      if (loginPassword.type == 'password') {
        loginPassword.type = 'text';
        showPasswordIcon.style.display = 'none';
        hidePasswordIcon.style.display = 'inline';
      } else {
        loginPassword.type = 'password';
        showPasswordIcon.style.display = 'inline';
        hidePasswordIcon.style.display = 'none';
      }
    },
    showPassword2() {
      var showPasswordIcon2 = document.getElementById('showPasswordIcon2');
      var hidePasswordIcon2 = document.getElementById('hidePasswordIcon2');
      var loginPassword3 = document.getElementById('loginPassword3');
      var loginPassword4 = document.getElementById('loginPassword4');
      if (loginPassword3.type == 'password') {
        loginPassword3.type = 'text';
        loginPassword4.type = 'text';
        showPasswordIcon2.style.display = 'none';
        hidePasswordIcon2.style.display = 'inline';
      } else {
        loginPassword3.type = 'password';
        loginPassword4.type = 'password';
        showPasswordIcon2.style.display = 'inline';
        hidePasswordIcon2.style.display = 'none';
      }
    }
  }
}
</script>

<style scoped>
main{
  min-height: 0!important;
  margin-top: -60px!important;
}
#showPassword,
#showPassword2{
  opacity: 0.6;
  cursor: pointer;
  width: 32px;
  margin-top: -28px;
  margin-left: 360px;
}
@media (max-width: 400px){
  #showPassword,
  #showPassword2{
    margin-left: calc(100% - 48px)!important;
  }
}
@media (max-width: 500px){
  #showPassword,
  #showPassword2{
    margin-left: calc(100% - 115px);
  }
}
#hidePasswordIcon,
#hidePasswordIcon2{
  display: none;
}
.Login{
  padding-top: 10vh;
}
.Login h2{
  color: rgba(0,0,0,0.8);
}
.loginmenu{
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  padding-top: 4px;
}
.forgotpassword a{
  font-size: 0.8em;
  text-decoration: underline;
}
.rememberme span{
  font-size: 0.8em;
}
.divider{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
  text-align: center;
  padding: 8px 0;
}
.divider:before,
.divider:after{
  content: ' ---------- ';
  color: rgba(0,0,0,0.4);
}
#LoginButton{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
}
#RegisterButton{
  margin: 0 auto;
  display: block;
  max-width: calc(300px - 32px);
  width: 100%;
}
.feedbackmessage{
  font-size: 0.8em;
}
.width-300{
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.disabled-inverted{
  background-color: lightgray !important;
}
</style>