import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import title from '@/mixins/title'
import { createPinia } from 'pinia'
import modal from "@/components/basic/modal";
import {configStore} from "@/store/configStore";

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = Vue.markRaw(router)
});


const vueProto = Vue.createApp(App).use(pinia).use(router).use(VueAxios, axios).mixin(title)

vueProto.component('VueModal', modal)
const config = configStore()
vueProto.axios.defaults.baseURL = config.projectURL
vueProto.config.warnHandler = () => null;
router.isReady().then(() => vueProto.mount('#App'))
