<template>
  <div class="content-1400 grid-12 pad-8">
    <div class="col-sp-12 row-sp-12">
      <helpbox/>
    </div>
    <div class="col-sp-12 col-sd-4 row-sp-12">
      <div id="ProfileMenu" class="pad-8">
        <section class="bgcolor-white br-16 pad-32">
        <h2>Kontoeinstellungen</h2>
          <ul>
            <li>
              <router-link :to="{name: 'Profile', params: {page: 'profil'}}" :class="page === 'profil' || page === '' ? 'active' : ''">Profilangaben</router-link>
            </li>
            <!--
            <li>
              <router-link :to="{name: 'Profile', params: {page: 'creditkonto'}}" :class="page === 'creditkonto' ? 'active' : ''">Credit-Konto</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Profile', params: {page: 'rechnungen'}}" :class="page === 'rechnungen' ? 'active' : ''">Rechnungen</router-link>
            </li>
            -->
          </ul>
        </section>
        <div class="pad-8"></div>
        <section class="bgcolor-white br-16 pad-32">
          <h2>Konto löschen</h2>
          <button @click="deleteModal = true" class="button fluid bgcolor-petrol color-white br-24">
            Account kündigen
          </button>
        </section>
      </div>
    </div>
    <div class="col-sp-12 col-sd-8 row-sp-12 pad-8">
      <section v-if="page === 'profil' || page === ''" class="bgcolor-white br-16 pad-24" style="padding-bottom: 32px!important;">
        <h2 class="pad-4">
          Profil bearbeiten
        </h2>
        <div class="pad-16" />
        <div class="grid-12">
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
            <div class="field simple pad-4">
              <label>Dein Avatar</label>
              <div id="SelectionPad">
                <div
                    class="grid-12"
                    style="max-width: 350px;"
                >
                  <div class="col-sp-4 row-sp-12 pad-4 t-center" @click="avatar = 'avatar-1.png'">
                    <img
                        src="/assets/images/avatars/avatar-1.png"
                        class="centered circular bgcolor-lightgrey is-square"
                        :class="{active: avatar === 'avatar-1.png'}"
                    >
                  </div>
                  <div
                      class="col-sp-4 row-sp-12 pad-4 t-center"
                      @click="avatar = 'avatar-2.png'"
                  >
                    <img
                        src="/assets/images/avatars/avatar-2.png"
                        class="centered circular bgcolor-lightgrey is-square"
                        :class="{active: avatar === 'avatar-2.png'}"
                    >
                  </div>
                  <div
                      class="col-sp-4 row-sp-12 pad-4 t-center"
                      @click="avatar = 'avatar-3.png'"
                  >
                    <img
                        src="/assets/images/avatars/avatar-3.png"
                        class="centered circular bgcolor-lightgrey is-square"
                        :class="{active: avatar === 'avatar-3.png'}"
                    >
                  </div>
                </div>
              </div>
              <input
                  type="hidden"
                  v-model="avatar"
                  name="avatar"
                  id="avatar"
              >
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12"></div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
            <div class="field simple pad-4">
              <label>Vorname</label>
              <input
                type="text"
                name="firstname"
                placeholder="Vorname"
                v-model="firstname"
                @keydown="status = ''"
                maxlength="20"
                required
              >
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
            <div class="field simple pad-4">
              <label>Nachname</label>
              <input
                type="text"
                name="lastname"
                placeholder="Nachname"
                v-model="lastname"
                @keydown="status = ''"
                maxlength="20"
                required
              >
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
            <div class="field simple pad-4">
              <label>E-Mail</label>
              <input
                  type="email"
                  name="email"
                  placeholder="E-Mail"
                  v-model="email"
                  @keydown="status = ''"
                  required
                  readonly
              >
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
            <div class="field simple pad-4">
              <label>Land</label>
              <select ref="country" name="country" v-model="country" style="max-width: 316px;" @change="status = ''">
                <option v-for="item in countrys" :key="item.iso" :value="item.iso">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-16"></div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
            <div class="field simple pad-4">
              <label>Unternehmen <span class="f-normal">(Optional)</span></label>
              <input
                  type="text"
                  name="company"
                  placeholder="Unternehmen"
                  maxlength="30"
                  v-model="company"
                  @keydown="status = ''"
              >
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
            <div class="field simple pad-4">
              <label>Position <span class="f-normal">(Optional)</span></label>
              <input
                type="text"
                name="position"
                placeholder="Position"
                maxlength="30"
                v-model="position"
                @keydown="status = ''"
              >
            </div>
          </div>
          <div class="pwCon col-sp-12 row-sp-12 pad-16">
            <div class="header" @click="emailExpand = !emailExpand" style="cursor: pointer;">E-Mail ändern
              <img src="/assets/images/dropdown-icon.png" style="vertical-align: middle; width: 20px;" v-if="!emailExpand">
              <img src="/assets/images/dropdown-icon.png" style="vertical-align: middle; width: 20px; transform: rotate(180deg);" v-else>
            </div>
            <div class="expand" :class="{'max-height': !emailExpand}">
              <div class="pad-16"></div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12">
                  <div class="field simple">
                    <label>E-Mail-Adresse</label>
                    <input
                        type="email"
                        name="password"
                        autocomplete="off"
                        placeholder="Neue E-Mail-Adresse eingeben"
                        v-model="email1"
                        @keydown="status = ''"
                    >
                  </div>
                  <div class="pad-8"></div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12">
                  <div class="field simple">
                    <label>E-Mail-Adresse wiederholen</label>
                    <input
                        type="email"
                        name="email2"
                        autocomplete="off"
                        placeholder="Neue E-Mail-Adresse eingeben"
                        v-model="email2"
                        @keydown="status = ''"
                    >
                  </div>
                  <div class="pad-8"></div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12">
                  <div class="field simple">
                    <label>Aktuelles Passwort</label>
                    <input id="loginPassword4" type="password" name="password" autocomplete="off" placeholder="Aktuelles Passwort" v-model="pw">
                    <div id="showPassword4" @click="showPassword4()">
                      <svg id="showPasswordIcon4" height="20px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/></svg>
                      <svg id="hidePasswordIcon4" height="20px" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/></svg>
                    </div>
                  </div>
                  <div class="pad-8"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="pwCon col-sp-12 row-sp-12 pad-16">
            <div class="header" @click="pwExpand = !pwExpand" style="cursor: pointer;">Passwort ändern
              <img src="/assets/images/dropdown-icon.png" style="vertical-align: middle; width: 20px;" v-if="!pwExpand">
              <img src="/assets/images/dropdown-icon.png" style="vertical-align: middle; width: 20px; transform: rotate(180deg);" v-else>
            </div>
            <div class="expand" :class="{'max-height': !pwExpand}">
              <div class="pad-16"></div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12">
                  <div class="field simple">
                    <label>Passwort</label>
                    <input
                        id="loginPassword"
                        type="password"
                        name="password"
                        autocomplete="off"
                        placeholder="Neues Passwort eingeben"
                        v-model="pw1"
                        @keydown="status = ''"
                    >
                    <div id="showPassword" @click="showPassword()">
                      <svg id="showPasswordIcon" height="20px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/></svg>
                      <svg id="hidePasswordIcon" height="20px" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/></svg>
                    </div>
                  </div>
                  <div class="pad-8"></div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12">
                  <div class="field simple">
                    <label>Passwort wiederholen</label>
                    <input
                        id="loginPassword2"
                        type="password"
                        name="password2"
                        autocomplete="off"
                        placeholder="Neues Passwort eingeben"
                        v-model="pw2"
                        @keydown="status = ''"
                    >
                  </div>
                  <div class="pad-8"></div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12">
                  <div class="field simple">
                    <label>Aktuelles Passwort</label>
                    <input
                        id="loginPassword3"
                        type="password"
                        name="password"
                        autocomplete="off"
                        placeholder="Aktuelles Passwort"
                        v-model="pw"
                    >
                  </div>
                  <div class="pad-8"></div>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4" v-if="fidoCheck">
            <div class="field simple pad-4">
              <button
                  class="button bgcolor-petrol color-white br-24"
                  @click="fido"
                  :disabled="fidoCreated"
              >
                FIDO-Login
              </button>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-4" v-if="fidos">
            <div class="field simple pad-4">
              <button
                  class="button bgcolor-petrol color-white br-24"
                  @click="fidoDel"
              >
                FIDO-Token löschen
              </button>
            </div>
          </div>-->
          <div class="col-sp-12 row-sp-12 pad-4">
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="emailAlreadyExist">
              Die E-Mail Adresse konnte nicht geändert werden, da die gewünschte E-Mail-Adresse bereits in Verwendung ist.
            </p>
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="firstname.length >= 20 || lastname.length >= 20">
              Vor- und/oder dein Nachname sind jeweils auf 20 Zeichen begrenzt.
            </p>
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="company.length >= 30 || position.length >= 30">
              Unternehmensname und/oder Position sind jeweils auf 30 Zeichen begrenzt.
            </p>
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="firstname.length == 0 || lastname.length == 0">
              Vor- und Nachname sind Pflichtfelder
            </p>
            <p
                class="feedbackmessage t-center pad-8 color-orange"
                v-if="status === 'pw' || pw1.length > 0 && pw2.length > 0 && pw1 != pw2 || pw1.length < 8 && pw2.length > 0"
            >
              Das Passwort ist nicht identisch, kürzer als 8 Zeichen oder dein aktuelles Passwort ist nicht korrekt.
            </p>
            <p
                class="feedbackmessage t-center pad-8 color-orange"
                v-if="status === 'email' || email1.length > 0 && email2.length > 0 && email1 != email2"
            >
              Die E-Mail-Adressen stimmen nicht überein.
            </p>
            <p
              class="feedbackmessage t-center pad-8 color-orange"
              v-else-if="status === 'error'"
            >
              Es ist ein Fehler aufgetreten, bitte versuche es erneut.
            </p>
            <p class="feedbackmessage t-center pad-8 color-petrol" v-else-if="status === 'ok'">
              Die Änderungen wurden erfolgreich übernommen.
            </p>
          </div>
          <div class="col-sp-12 row-sp-12 t-right pad-4">
            <div class="pad-16" />
            <button class="button bgcolor-petrol color-white br-24" :class="{disabled: (email1.length > 0 && email2.length > 0 && email1 != email2) || (pw1.length > 0 && pw2.length > 0 && pw1 != pw2 || pw1.length < 8 && pw2.length > 0) || status === 'ok' || status === 'start'}" :disabled="(email1.length > 0 && email2.length > 0 && email1 != email2) || (pw1.length > 0 && pw2.length > 0 && pw1 != pw2 || pw1.length < 8 && pw2.length > 0) || status === 'ok' || status === 'start'" @click="edit">
              Änderungen übernehmen
            </button>
          </div>
        </div>
      </section>
      <!--
      <section id="creditkonto" v-if="page == 'creditkonto'" class="bgcolor-white br-16 pad-24" style="padding-bottom: 32px!important;">
        <h2 class="pad-4">
          Mein Credit-Konto
        </h2>
        <div class="pad-16" />
        <div class="t-center">
          <h4 class="color-orange"> {{ user.credits }} </h4>
          <h5 class="color-orange">easyCROP - {{ user.credits === 1 ? 'Credit' : 'Credits' }}</h5>
          <div class="cta">
            <p v-if="user.credits > 95" class="color-orange" style="font-size: 0.8em;margin-bottom: 16px;">Derzeit beträgt das Maximalguthaben 100 Kauf-Credits. Der Mindestbestellwert beträgt 5 Credits.</p>
            <button class="button fluid color-white br-24" :class="{'bgcolor-orange': user.credits <= 95}" @click="purchaseModel = true" :disabled="user.credits > 95">
              Credit-Konto aufladen
            </button>
            <div class="pad-8"></div>
            <a style="width: calc(100% - 32px);" href="https://www.easycrop.de/preise/" target="_blank" class="button fluid bgcolor-petrol color-white br-24">
              Preise
            </a>
          </div>
        </div>
        <div class="pad-32"></div>
        <div>
          <h5 class="color-grey">Verfügbare Zahlungsoptionen:</h5>
          <div class="grid-12">
            <div class="col-sp-3 row-sp-12 pad-4">
              <img src="assets/images/payments/mastercard.svg" class="responsive-img"/>
            </div>
            <div class="col-sp-3 row-sp-12 pad-4">
              <img src="assets/images/payments/visacard.svg" class="responsive-img"/>
            </div>
            <div class="col-sp-3 row-sp-12 pad-4">
              <img src="assets/images/payments/americanexpress.svg" class="responsive-img"/>
            </div>
          </div>
        </div>
      </section>
      <section v-if="page == 'rechnungen'" class="bgcolor-white br-16 pad-24" style="padding-bottom: 32px!important;">
        <h2 class="pad-4">
          Meine Rechnungen
        </h2>
        <div class="pad-16" />
        <div v-for="(invoice, index) in invoices" :key="index" class="invoiceCon">
          <div>
            {{(invoice.total / 100).toFixed(2)+ ' ' + invoice.currency}}
          </div>
          <div class="marginAuto">
            {{invoice.date}}
          </div>
          <button class="button circular" :class="{'bgcolor-petrol color-white': !mailDisabled}" @click="mailInvoice(invoice.id)" :disabled="mailDisabled">
            Per E-Mail senden
          </button>
        </div>
      </section>
      -->
    </div>
  </div>
  <!--<div class="vueModal" v-if="deleteModal">
    <div class="vueModalOuter" @click="deleteModal = false"/>
    <div class="vueModalInner">
      <div class="pad-16" />
      <div class="t-center pad-8">
        <h3>Möchtest du deinen Account wirklich löschen?</h3>
      </div>
      <div class="pad-32 t-right">
        <button @click="deleteModal = false" class="button bgcolor-mint circular color-black" style="margin-right: 8px;">
          Abbrechen
        </button>
        <button type="button" @click="deleteAccount" class="button bgcolor-petrol circular color-white">
          Account löschen
        </button>
      </div>
    </div>
  </div>-->
  <VueModal :show="deleteModal" approve="Okay" @approve="deleteModal = false" @close="deleteModal = false">
    Schade, dass du dein Konto löschen möchtest. <br>
    Bitte sende eine E-Mail an <a class="color-orange" style="text-decoration: none;" href="mailto:info@easycrop.de">info@easycrop.de</a>. <br>
    Unser Support Team wird zeitnah mit dir in Kontakt treten.
  </VueModal>
  <VueModal approve="Okay" @approve="mailed = false" :show="mailed" @close="mailed = false">
    Wir haben deine Rechnung an die E-Mail-Adresse gesendet, die in deinen Profilangaben hinterlegt ist.
  </VueModal>
  <VueModal approve="Okay" @approve="emailSend = false" :show="emailSend" @close="emailSend = false">
    Wir haben dir eine E-Mail zum Bestätigen deiner E-Mail-Adresse geschickt
  </VueModal>
  <VueModal approveClass="bgcolor-petrol color-white" approve="Zum Checkout" cancel="Abbrechen" @approve="buy('C100')" :show="purchaseModel" @close="purchaseModel = false" @cancel="purchaseModel = false">
    <h3 style="margin-top: -8px; text-align: center;">Credit Konto aufladen</h3>
    <div class="pad-16"></div>
    <h3>{{credits}} Credits</h3>
    <vue3-slider v-model="credits" color="#135969" track-color="#ff8565" :always-show-handle="true" :handle-scale="3" :min="5" :max="100 - user.credits"/>
    <div class="pad-16"></div>
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import slider from "vue3-slider"
import helpbox from "@/components/basic/helpbox";

export default {
  name: "Profile",
  beforeRouteUpdate(){
    this.$nextTick(() => {
      this.config.loading = false
    })
  },
  props:['page'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  components: {
    "vue3-slider": slider,
    "helpbox": helpbox,
  },
  data(){
    return {
      invoices: [],
      mailed: false,
      purchaseModel: false,
      credits: 100 - this.user.credits,
      mailDisabled: false,
      deleteModal: false,
      status: 'start',
      avatar: this.user.avatar,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      email: this.user.email,
      company: this.user.company ?? '',
      position: this.user.position ?? '',
      country: this.user.country,
      countrys: [
        {iso: 'DE', name: 'Deutschland'},
        {iso: 'CH', name: 'Schweiz'},
        {iso: 'AT', name: 'Österreich'},
      ],
      pw:'',
      pw1: '',
      pw2: '',
      pwExpand: false,
      email1: '',
      email2: '',
      emailExpand: false,
      emailSend: false,
      fidoCheck: navigator.credentials && navigator.credentials.create,
      fidos: false,
      fidoCreated: false,
      emailAlreadyExist: false
    }
  },
  watch: {
    avatar(){
      this.status = ''
    }
  },
  mounted() {
    this.config.loading = false
    const _this = this
    if(this.$route.query.status === 'success'){
      _etracker.sendEvent(new et_UserDefinedEvent('easyCrop', 'Purchase', 'Finished', 'Purchase'))
    }
    setTimeout(() => {
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'login/update', fD)
          .then(response => {
            if(response.status === 250){
              const data = response.data
              this.user.credits = data.credits
            }
          })
    }, 2500)

    const fD = new FormData()
    fD.append('sessionID', this.auth.sessionID)
    this.axios.post(this.config.projectURL + 'profile/get', fD)
        .then(response => {
          console.log(response)
          if(response.status === 250){
            _this.fidos = response.data.fidos
            _this.invoices = response.data.invoices
          }
        })
  },
  methods: {
    edit(){
      const _this = this
      this.emailAlreadyExist = false
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('avatar', this.avatar)
      fD.append('firstname', this.firstname)
      fD.append('lastname', this.lastname)
      fD.append('country', this.country)
      fD.append('company', this.company)
      fD.append('position', this.position)
      fD.append('email', this.email1)
      fD.append('currentPassword', this.pw)
      fD.append('password', this.pw1)
      fD.append('password2', this.pw2)
      this.axios.post(this.config.projectURL + 'profile/edit', fD)
          .then(response => {
            console.log(response.status)
            if(response.status === 250){
              this.status = 'ok'
              const data = response.data
              this.user.email = data.email
              this.user.firstname = data.firstname
              this.user.lastname = data.lastname
              this.user.avatar = data.avatar
              this.user.country = data.country
              this.user.position = data.position
              this.user.company = data.company
              if(this.email1.length) this.emailSend = true
              this.email1 = ''
              this.email2 = ''
              this.pw = ''
              this.pw1 = ''
              this.pw2 = ''
            }else if(response.status === 251){
              this.status = 'pw'
            }else if(response.status === 252){
              this.status = 'error'
            }else if(response.status === 253){
              this.status = 'email'
            }else if(response.status === 254){
              this.emailAlreadyExist = true
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    deleteAccount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/deleteAccount', fD)
        .then(response => {
          if(response.status === 250) this.auth.logout()
        })
    },
    buy(type){
      const _this = this
      let priceID
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('price', type)
      fD.append('quantity', this.credits)
      this.axios.post(this.config.projectURL + 'stripecon/purchaseURL', fD)
          .then(response => {
            if(response.status === 250) location.href = response.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    customerPortal(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'stripecon/createCustomPortal', fD)
          .then(response => {
            if(response.status === 250) location.href = response.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    mailInvoice(id){
      console.log(id)
      const _this = this
      _this.mailDisabled = true
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('invoiceID', id)
      this.axios.post(this.config.projectURL + 'stripecon/mailInvoice', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250) _this.mailed = true
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            _this.mailDisabled = false
          })
    },
    showPassword(){
      var showPasswordIcon = document.getElementById('showPasswordIcon');
      var hidePasswordIcon = document.getElementById('hidePasswordIcon');
      var loginPassword = document.getElementById('loginPassword');
      var loginPassword2 = document.getElementById('loginPassword2');
      var loginPassword3 = document.getElementById('loginPassword3');
      if(loginPassword.type == 'password'){
        loginPassword.type = 'text';
        loginPassword2.type = 'text';
        loginPassword3.type = 'text';
        showPasswordIcon.style.display = 'none';
        hidePasswordIcon.style.display = 'inline';
      }else{
        loginPassword.type = 'password';
        loginPassword2.type = 'password';
        loginPassword3.type = 'password';
        showPasswordIcon.style.display = 'inline';
        hidePasswordIcon.style.display = 'none';
      }
    },
    showPassword4(){
      var showPasswordIcon4 = document.getElementById('showPasswordIcon4');
      var hidePasswordIcon4 = document.getElementById('hidePasswordIcon4');
      var loginPassword = document.getElementById('loginPassword4');
      if(loginPassword.type == 'password'){
        loginPassword4.type = 'text';
        showPasswordIcon4.style.display = 'none';
        hidePasswordIcon4.style.display = 'inline';
      }else{
        loginPassword4.type = 'password';
        showPasswordIcon4.style.display = 'inline';
        hidePasswordIcon4.style.display = 'none';
      }
    }
    /*,
    fido(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'login/fidoCreate', fD)
          .then(response => {
            const json = response.data
            if (json.success === false) throw new Error(json.msg)
            recursiveBase64StrToArrayBuffer(json);
            return json
          })
          .then(createCredentialArgs => {
            console.log(createCredentialArgs, 'cca')
            return navigator.credentials.create(createCredentialArgs);
          })
          .then(cred => {
            console.log(cred)
            const fD2 = new FormData()
            fD2.append('sessionID', _this.auth.sessionID)
            fD2.append('clientDataJSON', cred.response.clientDataJSON  ? arrayBufferToBase64(cred.response.clientDataJSON) : null)
            fD2.append('attestationObject', cred.response.attestationObject ? arrayBufferToBase64(cred.response.attestationObject) : null)
            console.log(cred.response.attestationObject, cred.response.clientDataJSON)
            this.axios.post(_this.config.projectURL + 'login/fidoRegister', fD2)
                .then(response => {
                  if(response.status === 250) {
                    _this.fidos = true
                    _this.fidoCreated = true
                  }
                })
                .catch(e => {
                  throw e
            })
          })
          .catch(e => {
            console.error(e)
          })
    },
    fidoDel(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'login/delFido', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250) _this.fidos = false
          })
    }*/
  }
}
</script>

<style scoped>
#showPassword{
  opacity: 0.6;
  cursor: pointer;
  width: 32px;
  margin-top: -28px;
  margin-left: 290px;
}
#hidePasswordIcon{
  display: none;
}
#showPassword4{
  opacity: 0.6;
  cursor: pointer;
  width: 32px;
  margin-top: -28px;
  margin-left: 290px;
}
#hidePasswordIcon4{
  display: none;
}
#SelectionPad img{
  max-width: 40px;
  border: 2px solid rgba(0,0,0,0.2);
  transition: 0.3s;
  opacity: 0.8;
}
#SelectionPad img:hover,
#SelectionPad img.active{
  border-color: #135969;
  opacity: 1;
}
#ProfileMenu h2{
  border-bottom: 2px solid rgba(0,0,0,0.075);
  padding-bottom: 8px;
  margin-bottom: 16px;
}
#ProfileMenu ul{
  padding: 0;
  margin: 0;
}
#ProfileMenu ul li{
  padding: 0;
  margin: 0;
  list-style: none;
}
#ProfileMenu ul li a{
  color: #135969;
  display: block;
  padding: 8px 0;
  font-weight: normal;
  cursor: pointer;
  font-size: 1.1em;
  text-decoration: none;
}
#ProfileMenu ul li a.active{
  font-weight: bold;
}
#creditkonto h4{
  font-size: 3.5em;
}
#creditkonto h5{
  font-size: 1.2em;
}
#creditkonto h5.color-grey{
  color: rgba(0,0,0,0.2);
}
#creditkonto .cta{
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  margin-top: 32px;
}
.invoiceCon{
  display: flex;
  margin-bottom: 15px;
}
.marginAuto{
  margin: auto;
}
.expand{
  max-height: 500px;
  overflow: hidden;
  transition: all 0.5s ease;
}
.max-height{
  max-height: 0px !important;
}
.disabled{
  background-color: grey !important;
}
</style>