<template>
<div @click.stop.prevent="changeImage" class="miniCon" :class="{active: images.currentIndex === index}" ref="con">
    <img :src="images.files[index].binarySmall">
  </div>
</template>

<script>
const {imageStore} = require("@/store/imageStore");
export default {
  name: "Miniature",
  props: ['index'],
  setup() {
    const images = imageStore()

    return {images}
  },
  mounted() {
    const _this = this
    this.sendOffset()
    this.images.$subscribe(() => {
      _this.sendOffset()
    })
  },
  methods: {
    changeImage(){
      this.$emit('saveFocus')
      this.images.name = this.images.files[this.index].path
    },
    sendOffset(){
      if(this.images.currentIndex === this.index) this.$emit('position', this.$refs.con.offsetLeft, this.$refs.con.offsetWidth)
    }
  }
}
</script>

<style scoped>
.miniCon{
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin: 10px;
  overflow: hidden;
  position: relative;
  background-color: #def7f1;
  display: inline-block;
}
.miniCon:hover{
  transition: 0.3s;
  box-shadow: 0 0 0 4px #fe8b6e;
  transform:scale(1.1);
  cursor: pointer;
}
img{
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.active{
  box-shadow: 0 0 0 4px #fe8b6e
}
</style>