<template>
  <div class="content-1400" id="SectionDashboard">
    <div>
      <helpbox />
    </div>
    <div class="grid-12">
      <div class="col-sp-12 row-sp-12" v-if="images.files.length < 1 && images.loaded">
        <div class="pad-8">
          <div class="bgcolor-white pad-24 br-16 content-box t-center" id="FileUploadBox" @drop="fileDrop">
            <img src="/assets/images/Upload-Kreis-petrol.svg" class="responsive-img width-100">
            <h3 class="color-petrol">
              Bild hochladen
            </h3>
            <h4 class="f-normal color-petrol">
              Ziehe deine Dateien (jpg, png, gif, webp) in die Upload-Box oder klicke auf „Datei auswählen”
            </h4>
            <div id="FileNameList" class="pad-8" />
            <div class="pad-36">
              <button id="FileSelectButton2" type="button" class="button bgcolor-mint color-petrol large">
                Dateien auswählen
              </button>
              <form>
                <input type="file" ref="files" :onchange="uploadFiles" accept="image/jpeg, image/png, image/gif, image/webp" id="FileInput2" multiple>
              </form>
            </div>
          </div>
        </div>
        <div class="pad-8" />
      </div>
    </div>
  </div>
  <div id="SectionUploads" v-if="images.files.length > 0 && images.loaded">
    <div class="bgcolor-petrol">
      <div class="content-1400">
        <div id="multiMenu" class="pad-8">
          <div style="display: inline;">
            <!--
              <router-link class="button br-8 color-petrol bgcolor-white" to="/formatsets" style="width: calc(100% - 48px);">
                Meine Format-Sets
              </router-link>
              -->
              <select id="PresetSelector" v-model="images.presetID" v-if="images.presets.length > 0" class="bgcolor-orange color-white">
                <option selected value="-1">
                  Format-Set für Stapelverarbeitung wählen
                </option>
                <option v-for="preset in images.presets" :key="preset.id" :value="preset.id">
                  {{ preset.name }}
                </option>
              </select>
              <button style="margin-left: 8px;" class="button br-8 color-petrol" @click="presetModal = true" v-else>
                Format-Set wählen...
              </button>
              <button class="button br-8 color-white bgcolor-orange" @click="serverCrop" v-if="images.presetID != -1 && images.selected.length > 0">
                Bilder runterladen <!--({{ costs.string }})-->
              </button>
            <input name="uploadmore" id="uploadmore" type="file" ref="files" :onchange="uploadFiles" accept="image/jpeg, image/png, image/gif, image/webp" multiple style="display: none;">
          </div>
          <div style="display: inline;" class="t-right">
            <!--
            <button @click="flatInfoBox = true" v-if="costs.flat && images.presetID != -1 && images.selected.length > 0" key="info" class="info color-white t-center button left image" style="border: none; background-color: #2D9091; margin-right: 8px;">
              <img src="/assets/images/info-im-kreis-petrol.svg" style="width: 20px;vertical-align: -4px;margin-right: 4px; filter: contrast(0) brightness(100);"> Flatrate
            </button>
            -->
            <button class="button br-8 color-petrol bgcolor-white" @click="deleteMulti" v-if="images.selected.length > 0" style="margin-right: 8px;">
              Ausgewählte löschen
            </button>
            <button class="button br-8 color-petrol bgcolor-white left image" @click="markAll">
              <img style="vertical-align: unset;" :src="images.selected.length > 0 ? 'assets/images/minus-petrol.svg' : 'assets/images/plus-petrol.svg'">
              {{ images.selected.length > 0 ? 'Alle abwählen' : 'Alle auswählen' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-1400">
      <div class="pad-16" />
      <!--
      <div v-if="flatInfoBox == true" style="background-color: #2D9091; max-width: calc(100% - 54px); margin: 0 auto;" class="color-white t-center br-16 pad-16">
        <img src="/assets/images/info-im-kreis-petrol.svg" style="max-height: 20px;vertical-align: -4px;margin-left: 4px; filter: contrast(0) brightness(100);">
        Tages-Flatrate - {{ dayEnd }} Stunden verbleibend
        <a target="_blank" href="https://www.easycrop.de/faq/#faq-tages-flatrate" class="color-orange f-bold" style="text-decoration: none; margin-left: 16px;">Mehr Informationen</a>
        <span style="cursor:pointer; float: right;" @click="flatInfoBox = false">
          <img src="/assets/images/kreuz-petrol.svg" style="width: 20px;vertical-align: -4px;margin-left: 4px; filter: contrast(0) brightness(100);">
        </span>
      </div>
      -->
      <!--<transition>
        <div v-if="wrongFile" key="infoEmpty" class="info bgcolor-mint color-orange t-center pad-4" style="margin-left: -32px;width: calc(100% + 32px);padding: 16px!important;">
          <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;"> Mindestens ein Dateityp wird leider nicht unterstützt.
        </div>
      </transition>-->
      <div class="grid-12 pad-20">
        <div class="uploadelement col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-8">
          <div class="pad-8">
            <div class="bgcolor-white pad-24 br-16 content-box t-center" id="FileUploadBox2" @drop="fileDrop">
              <img src="assets/images/Upload-Kreis-petrol.svg" class="responsive-img width-80">
              <h3 class="color-petrol">
                Bild hochladen
              </h3>
              <h4 class="f-normal color-petrol">
                Ziehe deine Dateien (jpg, png, gif, webp) in die Upload-Box oder klicke auf „Datei auswählen”
              </h4>
              <div class="pad-8">
                <button id="FileSelectButton" type="button" class="button bgcolor-mint color-petrol">
                  Dateien auswählen
                </button>
                <form>
                  <button id="FileUploadButton" type="submit" class="button bgcolor-petrol color-white">
                    Dateien hochladen
                  </button>
                  <input type="file" ref="files" :onchange="uploadFiles" accept="image/jpeg, image/png, image/gif, image/webp" id="FileInput" multiple>
                </form>
              </div>
            </div>
          </div>
        </div>
        <uploadedfiles v-for="(upload, index) in images.files" :key="upload" :id="index" :upload="upload" :status="upload.uploaded" :focus="images.focus[upload.path]" :check-animation="images.presetID != -1 && images.selected.length === 0" @select-single="selectSingle" />
      </div>
    </div>
  </div>
  <loader v-if="!images.loaded" />
  <!-- Modal -->
  <transition-group name="modal">
    <div class="vueModal" v-if="modal">
      <div class="vueModalOuter" @click="modal = false" />
      <div class="vueModalInner">
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12">
            <div class="pad-16">
              <h3 class="color-petrol">
                Bildvorschau
              </h3>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <div class="t-right pad-16">
              <button class="button bgcolor-mint circular color-black" @click="modal = false">
                X
              </button>
            </div>
          </div>
        </div>
        <div class="t-center pad-8" style="max-height: 50vh; overflow: scroll;">
          <img class="responsive-img" :src="img">
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div class="vueModal" v-if="modalDelete">
      <div class="vueModalOuter" @click="modalDelete = false" />
      <div class="vueModalInner">
        <div class="pad-16" />
        <div class="t-center pad-8">
          <h3>Möchtest du {{ images.deleteArray.length == 1 ? "das Bild" : "die " + images.deleteArray.length + " Bilder" }} wirklich löschen?</h3>
        </div>
        <div class="pad-32 t-right">
          <button style="margin-right: 8px;" class="button bgcolor-mint circular color-black" @click="modalDelete = false">
            Abbrechen
          </button>
          <button class="button bgcolor-petrol circular color-white" @click="deleteConfirm">
            {{ images.deleteArray.length == 1 ? "Bild" : "Bilder" }} löschen
          </button>
        </div>
      </div>
    </div>
    <div class="vueModal" v-if="downloadModal">
      <div class="vueModalOuter" @click="downloadModal = false" />
      <div class="vueModalInner">
        <div @click="downloadModal = false" class="t-right">
          <img src="/assets/images/kreuz-petrol.svg" class="closeIcon">
        </div>
        <div class="pad-16" />
        <div class="t-center pad-8">
          <h3 class="color-petrol f-normal">
            Download
          </h3>
        </div>
        <div class="t-center pad-8 errorMessage" v-if="error">
          <img src="assets/images/info-im-kreis-petrol.svg" class="responsive-img pad-16" >
          <h3 class="color-petrol f-normal">
            Hoppla! Leider lief etwas bei der Stapelverarbeitung schief.
            Bitte versuche es noch einmal mit weniger Bildern pro Download.
          </h3>
        </div>
        <div class="t-center pad-8 centerAll" style="min-height: 44px; position: relative;" v-else-if="!downloadFile">
          <loader size="small" v-if="downloadPercent == 100"></loader>
          <div class="loaderCon" v-else>
            <div class="slider">
              <div class="sliderInner" :style="{width: downloadPercent + '%'}" />
              <p class="sliderPercent">
                {{ downloadPercent }}%
              </p>
            </div>
            <div class="border">
              <div class="borderLeft" />
              <div class="borderRight" />
              <div class="borderFill" />
            </div>
          </div>
        </div>
        <div class="t-center pad-8" v-else>
          <div>
            <img :src="downloadFile" style="max-width: 200px; max-height: 200px" v-if="!downloadFileName.endsWith('.zip')">
            <a style="display: block; width: auto;" :href="downloadFile" :download="downloadFileName" class="button bgcolor-mint color-petrol fluid image right">Datei herunterladen</a>
          </div>
        </div>
        <div class="pad-8">
          <button class="button bgcolor-orange color-white fluid image left" @click="downloadModal = false">
            <img style="width: 7px; filter: brightness(100); vertical-align: -1px;" src="/assets/images/pfeil-links-petrol.svg"> Zurück zum Dashboard
          </button>
        </div>
      </div>
    </div>
    <div class="vueModal" v-if="presetModal">
      <div class="vueModalOuter" @click="presetModal = false" />
      <div class="vueModalInner" style="max-width: 700px;">
        <div @click="presetModal = false" class="t-right">
          <img src="assets/images/kreuz-petrol.svg" style="cursor: pointer;vertical-align: -5px; margin-right: 24px; margin-top: 24px; width: 16px;">
        </div>
        <div class="t-center pad-8">
          <img src="assets/images/info-im-kreis-petrol.svg" class="responsive-img width-100 pad-16">
          <h3 class="color-petrol f-normal">
            Hoppla! Du hast noch kein Format-Set für die Stapelverarbeitung angelegt.
          </h3>
        </div>
        <div class="t-center pad-8 centerAll">
          Nachdem du ein Format-Set erstellt, hast, kannst du mehrere Bilder<br>
          auf deinem Dashboard auswählen und mittels der Stapelverarbeitung<br>
          - mit nur einem Klick - in alle Bildgrößen aus deinem Format-Set zuschneiden.
        </div>
        <div class="pad-32 t-center">
          <a href="/formatsets" class="button bgcolor-petrol color-white">Erstelle jetzt dein erstes Format-Set!</a>
        </div>
      </div>
    </div>
    <div class="vueModal" v-if="formatModal">
      <div class="vueModalOuter" @click="formatModal = false" />
      <div class="vueModalInner">
        <div class="close" @click="formatModal = false">
          <img src="assets/images/kreuz-petrol.svg" style="height: 16px;">
        </div>
        <div class="pad-16" />
        <div class="t-center">
          <h3 class="color-petrol f-bold">
            Ups!
          </h3>
        </div>
        <div class="t-center pad-32 centerAll">
          EasyCROP konnte mindestens eine Datei nicht verarbeiten.
          <br><br>
          Diese Formate kannst du hochladen:<br>
          jpg, png, gif, webp
        </div>
        <div class="pad-32 t-right">
          <button class="button bgcolor-mint color-petrol fluid image f-bold right" @click="formatModal = false">
            Okay
          </button>
        </div>
      </div>
    </div>

    <div class="vueModal" v-if="fileSizeError">
      <div class="vueModalOuter" @click="fileSizeError = false" />
      <div class="vueModalInner">
        <div class="pad-16" />
        <div class="t-center pad-8">
          <img src="assets/images/info-im-kreis-petrol.svg" class="responsive-img width-100 pad-16">
          <h3 class="color-petrol f-normal">
            Du kannst maximal 100MB und 50 Dateien auf einmal hochladen. Bitte versuche es mit kleineren oder weniger Dateien.
          </h3>
        </div>
        <div class="pad-32 t-right">
          <button style="margin-right: 8px;" class="button bgcolor-mint circular color-black" @click="fileSizeError = false">
            Ok
          </button>
        </div>
      </div>
    </div>
    <!--
    <div class="vueModal" v-if="notEnoughCredits">
      <div class="vueModalOuter" @click="notEnoughCredits = false" />
      <div class="vueModalInner">
        <div class="pad-16" />
        <div class="t-center pad-8">
          <h3>Du hast nicht genügend Credits.</h3>
        </div>
        <div class="pad-32 t-right">
          <button style="margin-right: 8px;" class="button bgcolor-mint circular color-black" @click="notEnoughCredits = false">
            Abbrechen
          </button>
          <router-link class="button bgcolor-petrol circular color-white" :to="{name: 'Profile', params: {page: 'creditkonto'}}">
            Neue kaufen
          </router-link>
        </div>
      </div>
    </div>
    -->
  </transition-group>
  <!--
  <VueModal :show="modalFlat" approve="ok" @approve="modalFlat = false" @close="modalFlat = false">
    Deine Tagesflat ist nun aktiv.
  </VueModal>
  -->
</template>

<script>
import {ref} from "vue";
import axios from "axios";

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {imageStore} from "@/store/imageStore";

import uploads from "@/components/uploads";
import helpbox from "@/components/basic/helpbox";
import Loader from "@/components/basic/loader";

export default {
  name: "DashBoard",
  title: "Dashboard",
  components: {
    Loader,
    'uploadedfiles': uploads,
    'helpbox': helpbox,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const images = imageStore()
    const fileSizeError = ref(false)

    const files = ref(null)
    let status = ref('start')

    const uploadFiles = async() => {
          fileSizeError.value = false

          let fileSize = 0
          for(let i = files.value.files.length - 1; i >= 0; i--){
            fileSize += files.value.files[i].size
          }

          if(fileSize / 1000 / 1000 > 100 || files.value.files.length > 50) {
            fileSizeError.value = true
            files.value.value = ''
            return
          }

          status.value = 'uploaded'
          for(let i = files.value.files.length - 1; i >= 0; i--){
            images.files.unshift({name: files.value.files[i].name})
          }
          for(let i = 0; i < files.value.files.length; i++){
            let fD = new FormData();
            fD.append('sessionID', auth.sessionID);
            fD.append('file', files.value.files[i]);
            axios.post(config.projectURL + 'dashboard/upload',fD,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    images.files[i].progress = Math.floor(progressEvent.loaded*90 / totalLength)
                  }
                }
            )
                .then(function(response){
                  if(response.status === 250){
                    images.focus[response.data.path] = {
                      gcd: response.data.gcd,
                      face: response.data.face,
                      origin: response.data.origin,
                      originHeight: response.data.height,
                      originWidth: response.data.width,
                      src: response.data.path,
                      exif: JSON.stringify(response.data.exif)
                    }
                    images.files[i] = {
                      src: response.data.src,
                      path: response.data.path,
                      width: response.data.width,
                      height: response.data.height,
                      binarySmall: false,
                      binaryBig: false,
                      uploaded: false,
                      progress: 100
                    }
                    images.loadBinaryImage(i, 'small')
                        .then(() => {
                          images.files[i].uploaded = true
                        })
                  }

                })
                .catch(function(){
                  images.files[i].fail = true
                })
          }

    }
    //files.value.type = 'file'
    return {auth, config, user, images, uploadFiles, status, files, fileSizeError}
  },
  data(){
    return {
      modal: false,
      modalDelete: false,
      downloadModal: false,
      presetModal: false,
      formatModal: false,
      notEnoughCredits: false,
      downloadPercent: 0,
      tutorialSave: false,
      interval: null,
      modalFlat: false,
      flatInfoBox: false,
      downloadFileName: null,
      downloadFile: null,
      error: false
      //wrongFile: false
    }
  },
  computed: {
    /*
    costs() {
      try {
        let cost = this.images.selected.length * this.images.presetsByID(this.images.presetID).pattern.length
        if( cost >  50 - this.user.todayCreditsUsed){
          return {credits: 50 - this.user.todayCreditsUsed, flat: true, string: 50 - this.user.todayCreditsUsed + (50 - this.user.todayCreditsUsed === 1 ? ' Credit' : ' Credits')}
        }else{
          return {credits: cost, flat: false, string: cost + (cost === 1 ? ' Credit' : ' Credits')}
        }
      }catch (e) {
        return ''
      }
    },
    dayEnd() {
      const date = new Date()
      const dateBerlin = date.toLocaleString("en", {timeZone: 'Europe/Berlin', year: 'numeric', day: 'numeric', month: 'short'})
      const offsetBerlin = date.toLocaleString("en", {timeZone: 'Europe/Berlin', timeStyle: "long"}).split(" ").slice(-1)[0].replace('GMT', '')

      var timeStart = new Date();
      var timeEnd = new Date(Date.parse(dateBerlin + ' 23:59:59 GMT'+offsetBerlin));

      var difference = timeEnd - timeStart;
      var diff_result = new Date(difference);

      var hourDiff = diff_result.getHours();
      return hourDiff;
    }
     */
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    tutorial(){
      this.config.tutorial = true
      if(this.tutorialSave){
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        this.axios.post(this.config.projectURL + 'dashboard/tutorial', fD)
      }
    },
    fileDrop(e) {
      let files = e.dataTransfer.files
      for(let i = 0; i < files.length; i++){
        if(!['jpg', 'jpeg', 'gif', 'png', 'webp', 'heic'].includes(files[i].name.split('.').pop().toLowerCase())){
          this.formatModal = true
          e.preventDefault()
        }
      }
    },
    deleteMulti(){
      this.images.deleteArray = []
      for(let i = 0; i < this.images.selected.length; i++){
        this.images.deleteArray.push(this.images.selected[i])
      }
      this.modalDelete = true
    },
    deleteConfirm(){
      const _this = this
      let formData = new FormData();
      formData.append('name', JSON.stringify(this.images.deleteArray));
      formData.append('sessionID', this.auth.sessionID);
      axios.post(this.config.projectURL + '/dashboard/deleteImages', formData)
          .then(function(response){
            if(response.status == 250){
              for(let i = 0; i < _this.images.deleteArray.length; i++){
                let index = _this.images.files.map((el) => el.path).indexOf(_this.images.deleteArray[i])
                if(index > -1) _this.images.files.splice(index, 1)
              }
              if(_this.images.files.length == 0)_this.loading = false
              _this.modalDelete = false
              for(let key = 0; key < _this.images.deleteArray.length; key++){
                if(_this.images.selected.indexOf(_this.images.deleteArray[key]) > -1)_this.images.selected.splice(_this.images.selected.indexOf(_this.images.deleteArray[key]),1)
              }
              _this.images.deleteArray = []
            }
          })
    },
    fakeDownload(){
      const _this = this
      this.downloadModal = true
      this.downloadPercent = 0
      this.interval = setInterval(function (){
        if(_this.downloadPercent == 100) {
          clearInterval(_this.interval)
          return
        }
        _this.downloadPercent++
      }, 40)
    },
    serverCrop(){
      this.error = false
      const _this = this
      this.downloadFileName = null
      this.downloadFile = null

      const fD = new FormData()
      for(let key in this.images.selected){
        fD.append('images[]', this.images.selected[key])
      }
      fD.append('presetID', this.images.presetID)
      fD.append('sessionID', this.auth.sessionID)
      _this.downloadModal = true
      _this.downloadPercent = 0
      _this.fakeDownload()
      axios.post(this.config.projectURL + '/dashboard/cropImages', fD, {
        responseType: "arraybuffer",
        timeout: 60000
      })
          .then(function(response){
            console.log(response.status)
            if(response.status === 250){
              //_this.modalFlat = _this.user.todayCreditsUsed < 50 && (_this.user.todayCreditsUsed + _this.costs.credits) >= 50
              //_this.user.credits -= _this.costs.credits
              //_this.user.todayCreditsUsed += _this.costs.credits
              _this.downloadPercent = 100
              let filename
              if(response.headers['content-disposition'] !== undefined){
                const headerLine = response.headers['content-disposition']
                const startFileNameIndex = headerLine.indexOf('"') + 1
                const endFileNameIndex = headerLine.lastIndexOf('"')
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
              }else{
                filename = 'filename.zip'
              }

              const url = new Blob([response.data],{type:'application/zip'})
              _this.downloadFile = URL.createObjectURL(url)
              _this.downloadFileName = filename
            }else if(response.status === 251){
              clearInterval(_this.interval)
              _this.downloadModal = false
              //_this.notEnoughCredits = true
            }else{
              clearInterval(_this.interval)
              _this.downloadModal = false
            }
          })
        .catch(e => {
          clearInterval(_this.interval)
          _this.downloadPercent = 100
          _this.error = true
        })
    },
    markAll(){
      if(this.images.selected.length == 0){
        for(let i = 0; i < this.images.files.length; i++){
          this.images.selected.push(this.images.files[i].path)
        }
        this.images.selected = this.images.selected.filter((v, i, a) => a.indexOf(v) === i)
      }else{
        this.images.selected = []
      }
    }
  }
}
</script>

<style>
.content-box{
  overflow: hidden;
  position: relative;
}
.content-box p{
  font-size: 1em;
  margin-top: 8px;
  color: rgba(0,0,0,0.6);
}
#FileUploadBox{
  position: relative;
}
#FileInput,
#FileInput2{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
#FileUploadButton{
  position: relative;
  display: none;
  z-index: 9;
}
.uploadelement .uploadelement-box{
  position: relative;
  max-height: 270px;
  height: 270px;
}
.uploadelement .uploadelement-box .loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 8px;
  font-size: 1.2em;
  color: rgba(0,0,0,0.6);
  height: 100%;
}
.uploadelement .uploadelement-box .loader .loader-animation{
  width: calc(100% - 64px);
  height: 32px;
  position: absolute;
  top: 100px;
  left: 32px;
  background-color: rgba(0,0,0,0.05);
  border: 4px solid #135969;
  border-radius: 4px;
}
.uploadelement .uploadelement-box .loader .loader-animation .topleft{
  position: absolute;
  background-color: #135969;
  width: 16px;
  height: 4px;
  top: -4px;
  left: -16px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.uploadelement .uploadelement-box .loader .loader-animation .toptop{
  position: absolute;
  background-color: #135969;
  width: 4px;
  height: 12px;
  top: -20px;
  left: -4px;
  border-radius: 4px;
}
.uploadelement .uploadelement-box .loader .loader-animation .bottomright{
  position: absolute;
  background-color: #135969;
  width: 16px;
  height: 4px;
  bottom: -4px;
  right: -16px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.uploadelement .uploadelement-box .loader .loader-animation .bottombottom{
  position: absolute;
  background-color: #135969;
  width: 4px;
  height: 16px;
  bottom: -16px;
  right: -4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.uploadelement .uploadelement-box .loader .loader-animation .process{
  position: absolute;
  color: #ffffff;
  background-color: #ff8565;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  line-height: 32px;
}
.uploadelement .uploadelement-box .loader h6{
  position: absolute;
  width: 100%;
  top: 160px;
}
.uploadelement .uploadelement-box .top-checkbox{
  position: absolute;
  left: -8px;
  top: -8px;
  z-index: 3;
}
.uploadelement .uploadelement-box .top-checkbox input{
  transform: scale(1.5);
}
.uploadelement .uploadelement-box .left-side{
  position: absolute;
  width: calc(100% - 32px);
  left: 0;
  top: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  height: 100%;
}
.uploadelement .uploadelement-box .left-side .imageBox{
  overflow: hidden;
  width: 100%;
  height: 180px;
  margin-bottom: 16px;
  text-align: center;
  border-radius: 8px;
}
.uploadelement .uploadelement-box .left-side .imageBox img{
  width: auto;
  height: 100%;
  max-height: 200px;
  border-radius: 8px;
}

.uploadelement .uploadelement-box .right-side{
  position: absolute;
  width: 32px;
  right: 0;
  top: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
}
.uploadelement .uploadelement-box .right-side ul{
  margin: 0;
  padding: 0;
  margin-top: 20px;
}
.uploadelement .uploadelement-box .right-side ul li{
  margin: 0;
  padding: 4px;
  list-style: none;
  margin-bottom: 60px;
  text-align: center;
  vertical-align: middle;
  padding-right: 8px;
  height: 27px;
}
.uploadelement .uploadelement-box .right-side ul li:hover{
  background-color: #FC8465;
}
.uploadelement .uploadelement-box .right-side ul li img{
  width: 20px;
  cursor: pointer;
  vertical-align: middle;
}
#PresetsDropdown{
  margin-left: 8px;
}
#SectionUploads{
  padding-bottom: 80px;
}
.uploadsloader{
  text-align: center;
  padding: 64px;
}
.uploadsloader img{
  width: 100px;
  animation: scale 1s;
  animation-iteration-count: infinite;
}
@keyframes scale {
  0%   {
    transform: scale(1);
  }
  50%  {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.modal{
  /*display: block !important;*/
  visibility: visible !important;
}
#multiMenu .button,
#multiMenu #PresetSelector{
  margin-right: 8px;
  margin-bottom: 8px;
  margin-bottom: 8px;
}

.top-checkbox [type="checkbox"]:disabled:not(:checked) + label:before,.top-checkbox [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #135969;
  background-color: #dddddd;
  cursor: pointer;
}
.top-checkbox:hover [type="checkbox"]:disabled:not(:checked) + label:before,.top-checkbox:hover [type="checkbox"]:disabled:checked + label:before {
  border-color: #FC8465;
  transition: 0.3s;
  transform: scale(1.1);
}

.top-checkbox [type="checkbox"]:not(:checked) + label:before,.top-checkbox [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  border: 2px solid #cccccc;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
  cursor: pointer;
}
.top-checkbox [type="checkbox"]:disabled:checked + label:after {
  cursor: pointer;
  color: #FC8465;
}
.top-checkbox [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.top-checkbox [type="checkbox"]:not(:checked) + label:after,.top-checkbox  [type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: 0px;
  left: 5px;
  font-size: 20px;
  line-height: 1.2;
  color: transparent;
  transition: all .2s;
  cursor: pointer;
}
.pad-18{
  padding: 18px !important;
}
#PresetSelector{
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px 16px;
  padding-right: 40px;
  border-radius: 8px;
  border: none;
  font-size: 1em;
  background-image: url('../../public/assets/images/pfeil-unten-weiss.svg');
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.boxTitle{
  /*
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}
.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.5s ease;
}
.collapse-leave-active {
  position: absolute;
}

.collapse-enter-from,
.collapse-leave-to {
  opacity: 0;
  max-height: 0;
}
.collapse-enter-to,
.collapse-leave-from {
  opacity: 1;
}
.collapse-move{
  transition: all 0.3s ease;
}.progressHeader{
   width: 100%;
   height: 44px;
   margin: 5px 0;
   color: white;
 }
.progressHeader .step{
  width: 33%;
  height: 100%;
  background-color: #def7f1;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.progressHeader .icon{
  height: 50%;
  margin-right: 20px;
  filter: brightness(100);
  margin-left: 25px;
}
.progressHeader .icon.home{
  filter: invert();
}
.progressHeader .done{
  background-color: #FC8465!important;
}
.progressHeader .done::after{
  content: '';
  position: absolute;
  left: 100%;
  width: 0px;
  height: 0px;
  -webkit-transform:rotate(360deg);
  border-style: solid;
  border-width: 22px 0 22px 22px;
  border-color: transparent transparent transparent #FC8465;
  z-index: 3;
}
.progressHeader .current{
  background-color: #135969 !important;
}
.progressHeader .current::after{
  content: '';
  position: absolute;
  left: 100%;
  width: 0px;
  height: 0px;
  -webkit-transform:rotate(360deg);
  border-style: solid;
  border-width: 22px 0 22px 22px;
  border-color: transparent transparent transparent #135969;
  z-index: 3;
}
.loaderCon{
  width: 50%;
  height: 44px;
  position: relative;
}
.slider{position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 18px);
}
.sliderInner{
  height: 100%;
  width: 0%;
  background-color: #def7f1;
}
.border{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
}
.border .borderLeft{
  background: no-repeat url("../../public/assets/images/icons/crop-left.png");
  background-size: contain;
  height: 100%;
  width: 15px;
  float: left;
}
.border .borderRight{
  background: no-repeat url("../../public/assets/images/icons/crop-right.png");
  background-size: contain;
  height: 100%;
  width: 15px;
  float: right;
}
.border .borderFill{
  background: repeat-x url("../../public/assets/images/icons/crop_fill.png");
  background-size: contain;
  height: 100%;
  margin: 0 12px;
}
p.sliderPercent{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #135969;
}
.centerAll{
  display: flex;
  align-items: center;
  justify-content: center;
}
.fileName {
  position: relative;
  width: 100%;
}

.fileName::after {
  content: attr(data-filetype);
  position: absolute;
  right: 0;
  top: 0;
}

.fileName p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 22px;
}

.fileName p::after {
  content: '';
  background: #FFF;
  position: relative;
  padding-right: 100%;
  z-index: 1;
}
.closeIcon{
  cursor: pointer;
  vertical-align: -5px;
  margin-right: 24px;
  width: 16px;
}
@media (max-width: 768px) {
  #multiMenu a,
  #multiMenu selection,
  #multiMenu button{
    display: block;
  }
  #multiMenu #PresetSelector{
    margin: 8px 0 0 0;
    width: calc(100% - 16px);
  }
  #multiMenu button{
    width: calc(100% - 16px);
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0!important;
  }
}
#multiMenu .t-right{
  float: unset;
}
@media (min-width: 1350px) {
  #multiMenu .t-right{
    float: right;
  }
}
.close{
  position: absolute;
  top: 10px;
  right: 10px;
}
.errorMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.errorMessage img {
  width: 32px;
  margin: 0 12px;
}
</style>