<template>
  <div class="preview">
    <p class="preview">
      <span v-if="pattern.name != null && pattern.name != 'null' && pattern.name != ''">{{ pattern.name }}: </span>{{ pattern.width + ' x ' + pattern.height }}
    </p>
    <div class="overrideTarget">
      <div class="overlay"></div>
      <canvas class="preview" ref="canvas" :width="pattern.width" :height="pattern.height"/>
    </div>
    <div class="overrideFocus" v-if="overrideFocus">
      <img class="attention left" src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg">
      <img class="attention right" src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg">
      <h5 class="color-orange t-center">
        Bitte wähle einen kleineren Fokusbereich, falls dieser Zuschnitt nicht optimal ist.
      </h5>
    </div>
  </div>
</template>

<script>
require('@/modules/canvas')
export default {
  name: "Preview",
  props: ['pattern', 'focus', 'img', 'imagedata', 'lock'],
  computed: {
    overrideFocus () {
      let factor
      if(this.imagedata.naturalHeight / this.imagedata.naturalWidth < this.pattern.height / this.pattern.width){
        factor = this.imagedata.naturalHeight / this.pattern.height
      }else{
        factor = this.imagedata.naturalWidth / this.pattern.width
      }
      return this.focus.width / factor > this.pattern.width || this.focus.height / factor > this.pattern.height
    }
  },
  watch: {
    pattern(){
      this.drawCanvas()
    },
    img(){
      this.drawCanvas()
    },
    lock(){
      const _this = this
      this.drawCanvas()
          .then(()=> {
            if(!_this.lock) _this.$emit('ready')
          })
    }
  },
  mounted () {
    this.drawCanvas()
  },
  methods: {
    async drawCanvas() {
      return new Promise((resolve, reject) => {
        if(this.pattern.width < 1 || this.pattern.height < 1) return
        this.$refs.canvas.renderImageFull(this.img, this.imagedata, this.pattern, this.focus, this.lock)
        this.$nextTick(() => {
          this.$refs.canvas.renderImageFull(this.img, this.imagedata, this.pattern, this.focus, this.lock)
              .then(resolve)
              .catch(reject)
        })
      })
    },
    canvasToDataUri(ext){
      switch (ext.toLowerCase()){
        case 'jpg':
        case 'jpeg':
          ext = 'jpeg'
          break;

        case 'webp':
          ext = 'webp'
          break;

        case 'png':
        default:
          ext = 'png'
          break;
      }
      return this.$refs.canvas.toDataURL("image/"+ext)
    },
    getDimension(){
      return {width: this.pattern.width, height: this.pattern.height, name: this.pattern.name}
    },
    getRaw(){
      return this.$refs.canvas
    }
  }
}
</script>

<style scoped>
canvas.preview{
  pointer-events: none;
}
div.overrideTarget{
  position: relative;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>