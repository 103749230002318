<template>
  <div class="content-1400">
    <div>
      <helpbox/>
    </div>
  </div>
  <!--
  <div class="bgcolor-petrol pad-8">
    <div class="content-1400 pad-8">
      <a href="dashboard" class="button color-petrol bgcolor-white" style="margin-right: 8px;">Zum Dashboard</a>
    </div>
  </div>
  -->
  <div class="content-1400">
    <div class="grid-12">
      <div class="col-sp-12 row-sp-12 grid-12 pad-4">
        <div class="col-sp-12 col-tb-6 col-ld-4 row-sp-12 pad-4">
          <div class="bgcolor-white t-center br-8 pad-16 newbox" @click="createPreset = true">
            <img src="assets/images/plus-kreis-mint.svg" class="responsive-img width-80">
            <button class="button bgcolor-mint color-petrol">
              Format-Set erstellen
            </button>
          </div>
        </div>
        <presets-template v-for="(preset, index) in images.presets" :key="preset.id" ref="presetItem" :preset="preset" :index="index"/>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="createPreset" id="CreatePreset" class="vueModal">
      <div class="vueModalOuter" @click="createPreset = false"/>
      <div class="vueModalInner">
        <div class="pad-16" />
        <div class="t-center pad-8">
          <h3>Neues Format-Set erstellen</h3>
        </div>
        <transitionGroup name="info">
          <div v-if="infoDuplicate" key="infoDuplicate" class="info color-orange t-center">
            <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;">
            Ups.<br>
            Es gibt bereits ein Format-Set mit diesem Namen.<br>
            Bitte wähle einen neuen Namen.
          </div>
          <div v-if="fileNameError" key="fileNameError" class="info color-orange t-center">
            <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;">
            Bitte verwenden Sie keine Sonderzeichen im Format-Namen.
          </div>
        </transitionGroup>
        <div class="pad-32 t-center">
          <div class="field simple">
            <input v-model="newPresetName" type="text" ref="newPresetInput" placeholder="Name des Format-Sets" @keyup.enter="newPreset">
          </div>
        </div>
        <div class="pad-32 t-right">
          <button style="margin-right: 8px;" class="button bgcolor-mint circular color-black" @click="createPreset = false">
            Abbrechen
          </button>
          <button class="button bgcolor-petrol color-white br-24" @click="newPreset">
            Erstellen
          </button>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {imageStore} from "@/store/imageStore";
import XRegExp from 'xregexp';


import presets from "@/components/presets";
import helpbox from "@/components/basic/helpbox";

export default {
  name: 'FormatSets',
  components: {
    'presets-template': presets,
    'helpbox': helpbox,
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const images = imageStore()

    return {auth, config, user, images}
  },
  data() {
    return{
      createPreset: false,
      infoDuplicate: false,
      fileNameError: false,
    }
  },
  watch: {
    name(){
      this.saved = false
    },
    createPreset(){
      this.$nextTick(() => {
        if(this.createPreset) this.$refs['newPresetInput'].focus()
      })
    }
  },
  mounted() {
    const _this = this
    _this.config.loading = false
  },
  methods: {
    newPreset(){
      const regex = XRegExp(/[^\p{L}\p{N}ÄÖÜäöüß\s]/gu);
      if (regex.test(this.newPresetName)) {
        this.fileNameError = true;
      } else {
        this.fileNameError = false;
        const _this = this
        const fD = new FormData()
        fD.append('name', this.newPresetName)
        fD.append('sessionID', this.auth.sessionID)
        this.axios.post(this.config.projectURL + '/formatsets/addPreset', fD)
            .then(function (response){
              console.log(response)
              if(response.status === 250){
                _this.images.presets.unshift({
                  id: response.data.id,
                  name: response.data.name,
                  pattern: []
                })
                _this.newPresetName = ''
                _this.createPreset = false
                _this.infoDuplicate = false
              }else if(response.status === 251){
                _this.infoDuplicate = true
              }
            })
      }

    }

  }
}
</script>

<style>
.content-box{
  overflow: hidden;
  position: relative;
}
.content-box p{
  font-size: 1em;
  margin-top: 8px;
  margin-bottom: 32px;
  color: rgba(0,0,0,0.6);
}
table{
  width: 100%;
  padding: 4px;
}
table thead tr td{
  font-weight: bold;
}
.pad-left-20{
  padding-left: 20px;
}

.newbox{
  min-height: 300px;
  border-radius: 16px;
  border: 2px dashed rgba(0,0,0,0.2);
  cursor: pointer;
  transition: 0.3s;
}
.newbox:hover{
  transition: 0.3s;
  background-color: #def7f1;
}
.newbox img{
  display: block;
  margin: 0 auto;
  margin-top: 18%;
  margin-bottom: 32px;
}
#PresetsBox .field.simple input{
  width: calc(100% - 32px);
}
</style>