<template>
  <div class="content-1400">
    <div>
      <helpbox />
    </div>
  </div>
  <div>
    <div class="progressHeader">
      <div class="step" :class="{done: step > 0}">
        <div class="stepbutton" @click="saveFocus(); $router.push('/dashboard')">
          <img src="/assets/images/icons/Home-icon.svg" class="icon home">
          <p>Zurück zum Dashboard</p>
        </div>
      </div>
      <div class="step" :class="{done: step > 1, current: step == 1}" @click="back(1)">
        <div class="stepbutton">
          <img src="/assets/images/lupe-weiss.svg" class="icon">
          <p>Fokusbereich festlegen</p>
        </div>
      </div>
      <div class="step" @click="next" :class="{done: step > 2, current: step == 2}">
        <div class="stepbutton">
          <img src="/assets/images/crop-weiss.svg" class="icon">
          <p>Bildformat wählen</p>
        </div>
      </div>
    </div>
    <div class="bgcolor-petrol">
      <div class="content-1400">
        <div class="pad-8">
          <p class="pad-8 color-white countText" style="display: inline;">
            Bild {{ images.currentIndex + 1 }} von {{ images.files.length }} auf dem Dashboard
          </p>
          <button class="button br-8 tiny color-petrol bgcolor-mint" id="deleteButton" @click="modalDelete = true">
            <img src="assets/images/papierkorb-petrol.svg" style="vertical-align: -4px; width: 16px; margin-right: 8px;"> Dieses Bild löschen
          </button>
          <slider-bar @number="number" @saveFocus="saveFocus" />
        </div>
      </div>
    </div>
    <div class="content-1400">
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 pad-8" />
        <div class="col-sp-12 col-ld-8 row-sp-12 pad-16 bgcolor-mint">
          <div class="imageBox pad-8 bgcolor-mint br-8 fullContainer">
            <div class="" v-show="step == 1">
              <img ref="image" :src="images.currentImage.binaryBig" class="responsive-img mh80" id="cropperTarget">
            </div>
            <div v-if="step == 2">
              <div class="uploadsloader" v-if="loading">
                <img src="assets/images/Image-zuschneiden-petrol.svg">
                <h5 class="color-petrol">
                  Lädt...
                </h5>
              </div>
              <div v-if="cropper" :style="{opacity: downloadModal ? 0 : 1}">
                <div class="previewContainer" v-if="previewType === 'list'">
                  <canvas-preview ref="multicanvas" v-for="(pattern) in images.presetsByID(selectedPreset)['pattern']" :imagedata="cropper.getImageData()" :focus="{x: x, y: y, width: width, height: height}" :pattern="pattern" :img="$refs['image'].src" :key="pattern" :lock="lock" @ready="addReady" />
                </div>
                <div class="previewContainer" v-else-if="previewType == 'radio'">
                  <canvas-preview ref="singlecanvas" :imagedata="cropper.getImageData()" :focus="{x: x, y: y, width: width, height: height}" :pattern="calcPattern" :img="$refs['image'].src" :lock="lock" @ready="download" />
                </div>
                <div class="previewContainer" v-else>
                  <canvas-preview ref="singlecanvas" :imagedata="cropper.getImageData()" :focus="{x: x, y: y, width: width, height: height}" :pattern="{width: scaleX, height: scaleY}" :img="$refs['image'].src" :lock="lock" @ready="download" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-ld-4 row-sp-12 pad-16">
          <div v-if="step == 1">
            <div class="br-8 bgcolor-white pad-32">
              <div>
                <h2 class="color-petrol f-normal">
                  1. Lege deinen Fokusbereich fest
                </h2>
                <p style="color: rgba(0,0,0,0.6);">
                  <br>
                  Um deine Bilder zuzuschneiden, wählst du zuerst deinen
                  <a class="color-orange f-bold tooltip">Fokusbereich<img src="/assets/images/fragezeichen-im-kreis-orange.svg" style="width: 20px;vertical-align: top;margin-left: 4px;">
                    <span class="tooltiptext">Der Fokusbereich deines Bildes bleibt auf allen zugeschnittenen Formaten erhalten. Du kannst die Auswahl per Drag & Drop anpassen.</span>
                  </a>.
                  <br><br>
                  Was du nicht auswählst, wird unter Umständen abgeschnitten. Bitte wähle deinen Fokusbereich dennoch so klein wie möglich, damit das easyCROP Tool weiß, was genau dir wichtig ist.
                </p>
              </div>
            </div>
            <div class="pad-4" />
            <div class="br-8 bgcolor-white color-petrol pad-32">
              <div class="t-center">
                <button class="button bgcolor-petrol color-white fluid image right pad-8" @click="next">
                  Speichern und Bildformat wählen<img src="/assets/images/pfeil-rechts-petrol.svg" style="width: 7px; filter: brightness(100); vertical-align: unset;">
                </button>
              </div>
            </div>
            <div class="pad-4" />
            <div v-if="images.length > 1">
              <div class="br-8 bgcolor-white color-petrol pad-32">
                <button class="button bgcolor-mint color-petrol fluid image left" @click="saveFocus(-1)" v-if="images.currentIndex > 0">
                  <img style="width: 7px; vertical-align: unset;" src="/assets/images/pfeil-links-petrol.svg">Speichern & Voheriges Bild
                </button>
                <div class="pad-4" />
                <button class="button bgcolor-mint color-petrol fluid image right" @click="saveFocus(+1)" v-if="images.currentIndex + 1 < images.length">
                  Speichern & Nächstes Bild <img style="width: 7px; vertical-align: unset;" src="/assets/images/pfeil-rechts-petrol.svg">
                </button>
              </div>
            </div>
          </div>
          <div v-if="step == 2">
            <h2 class="color-petrol pad-8">
              2. Wähle dein Bildformat
            </h2>
            <div class="br-8 bgcolor-white color-petrol pad-32">
              <div>
                <h3 class="color-petrol f-normal">
                  Wähle ein gespeichertes Format-Set
                </h3>
                <div class="field simple fluid" style="position: relative;">
                  <transition name="modal">
                    <img class="pulse-hint" v-if="hint" src="/assets/images/gluehbirne-mit-strahlen-orange.svg">
                  </transition>
                  <select v-model="selectedPreset" :class="{newPreset: newPreset}">
                    <option disabled selected value="-1">
                      Wählen...
                    </option>
                    <option v-for="preset in images.presets" :value="preset.id" :key="preset.id">
                      {{ preset.name }}
                    </option>
                  </select>
                </div>
                <div class="pad-8" />
                <transition-group name="button">
                  <button class="button bgcolor-petrol color-white fluid image right" @click="startZip" v-if="selectedPreset > -1">
                    ZIP: {{ costs.string }}<img style="margin-top: 4px;" src="/assets/images/download-strich-weiss.svg">
                  </button>
                  <div v-if="costs.flat && selectedPreset > -1" key="info" class="info color-orange t-center pad-4" style="margin-left: -32px;width: calc(100% + 32px);font-size: 0.8em; padding: 16px!important;">
                    Tages-Flatrate gültig bis {{ dayEnd }} Uhr. <img src="/assets/images/fragezeichen-im-kreis-orange.svg" style="max-height: 20px;vertical-align: -5px;margin-left: 8px;">
                  </div>
                </transition-group>
              </div>
            </div>
            <div class="pad-4" />
            <div class="br-8 bgcolor-white color-petrol pad-32">
              <div>
                <h3 class="color-petrol f-normal">
                  oder klicke auf das gewünschte Seitenverhältnis:
                </h3>
                <div class="grid-12">
                  <div class="col-sp-12 col-ld-6 row-sp-12">
                    <table>
                      <tr @click="presetRadio = '1-1'" style="cursor: pointer;">
                        <td width="20%">
                          <input id="radio11" type="radio" v-model="presetRadio" name="previewType" value="1-1">
                        </td>
                        <td width="20%">
                          1
                        </td>
                        <td width="10%">
                          :
                        </td>
                        <td width="20%">
                          1
                        </td>
                        <td width="30%">
                          <img src="/assets/images/1-1.png" class="bildmaß">
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-sp-12 col-ld-6 row-sp-12">
                    <table>
                      <tr @click="presetRadio = '2-1'" style="cursor: pointer;">
                        <td width="20%">
                          <input id="radio21" type="radio" v-model="presetRadio" name="previewType" value="2-1">
                        </td>
                        <td width="20%">
                          2
                        </td>
                        <td width="10%">
                          :
                        </td>
                        <td width="20%">
                          1
                        </td>
                        <td width="30%">
                          <img src="/assets/images/1-2.png" class="bildmaß bild90">
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-sp-12 col-ld-6 row-sp-12">
                    <table @click="presetRadio = '9-16'" style="cursor: pointer;">
                      <tr>
                        <td width="20%">
                          <input id="radio916" type="radio" v-model="presetRadio" name="previewType" value="9-16">
                        </td>
                        <td width="20%">
                          9
                        </td>
                        <td width="10%">
                          :
                        </td>
                        <td width="20%">
                          16
                        </td>
                        <td width="30%">
                          <img src="/assets/images/9-16.png" class="bildmaß">
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-sp-12 col-ld-6 row-sp-12">
                    <table>
                      <tr @click="presetRadio = '16-9'" style="cursor: pointer;">
                        <td width="20%">
                          <input id="radio169" type="radio" v-model="presetRadio" name="previewType" value="16-9">
                        </td>
                        <td width="20%">
                          16
                        </td>
                        <td width="10%">
                          :
                        </td>
                        <td width="20%">
                          9
                        </td>
                        <td width="30%">
                          <img src="/assets/images/9-16.png" class="bildmaß bild90">
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="pad-4" />
              </div>
              <div>
                <h3 class="color-petrol f-normal">
                  oder gib die gewünschten Bildmaße manuell ein:
                </h3>
                <transition-group name="info">
                  <p class="info bgcolor-orange color-white t-center pad-4 br-8" v-if="info">
                    Die maximalen Bildmaße sind 10.000px
                  </p>
                  <p class="info bgcolor-orange color-white t-center pad-4 br-8" v-if="wrongChar">
                    Ups.
                    Du hast ungültige Zeichen eingegeben.
                    Bitte gib deine Bildmaße als ganze Zahlen ein.
                  </p>
                  <div class="pad-8" />
                </transition-group>
                <div class="grid-12">
                  <div class="col-sp-4 row-sp-12 pad-4">
                    <div class="field simple">
                      <input style="width: calc(100% - 40px);" type="number" class="text-input-nonumber" v-model="scaleX" @keydown="noNegative($event)" placeholder="Breite in px">px
                    </div>
                  </div>
                  <div class="col-sp-4 row-sp-12 pad-8 t-center">
                    <img src="/assets/images/swap.png" id="swapIcon" @click="swap">
                  </div>
                  <div class="col-sp-4 row-sp-12 pad-4">
                    <div class="field simple">
                      <input style="width: calc(100% - 40px);" type="number" class="text-input-nonumber" v-model="scaleY" @keydown="noNegative($event)" placeholder="Höhe in px">px
                    </div>
                  </div>
                </div>
                <div class="pad-8" />
                <div class="pad-4">
                  Bildmaße als Format-Set speichern
                </div>
                <div class="field simple fluid">
                  <select v-model="addToPreset">
                    <option selected value="-2">
                      Wählen...
                    </option>
                    <option selected value="-1">
                      Neues Preset erstellen...
                    </option>
                    <option v-for="preset in images.presets" :value="preset.id" :key="preset.id">
                      {{ preset.name }}
                    </option>
                  </select>
                  <input type="text" v-model="presetName" v-if="addToPreset == -1" placeholder="Name des Presets" @keypress.enter="savePreset">
                  <div class="pad-4" />
                </div>
                <button v-if="addToPreset != -2" class="button color-petrol fluid image right" @click="savePreset" :class="{btn_disabled: presetSaved || (addToPreset == -1 && presetName.length == 0), 'bgcolor-mint': !presetSaved && (addToPreset != -1 || presetName.length > 0)}" :disbaled="presetSaved || (addToPreset == -1 && presetName.length == 0)">
                  {{ presetSaved ? 'Gespeichert' : 'Hinzufügen' }}
                </button>
                <div class="pad-4" />
                <transition-group name="button">
                  <button class="button bgcolor-petrol color-white fluid image right" @click="download" v-if="selectedPreset == -1">
                    {{ costs.string }}<img style="margin-top: 4px;" src="/assets/images/download-strich-weiss.svg">
                  </button>
                  <div v-if="costs.flat && selectedPreset == -1" key="info" class="info color-white t-center pad-4" style="background-color: rgb(45, 144, 145);font-size: 0.8em;width: calc(100% - 32px);padding: 16px !important;margin-top: 8px;border-radius: 4px;">
                    <img src="/assets/images/info-im-kreis-petrol.svg" style="max-height: 20px;vertical-align: -4px;margin-left: 4px; filter: contrast(0) brightness(100);">
                    Tages-Flatrate - {{ dayEnd }} Stunden verbleibend
                    <a target="_blank" href="https://www.easycrop.de/faq/#faq-tages-flatrate" class="color-orange f-bold" style="text-decoration: none; margin-left: 16px;">Mehr Informationen</a>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal vueOverrideModal" v-if="downloadModal" :style="{display: downloadModal ? 'block' : 'none' }">
      <div class="modalTransition" @click="downloadModal = false" />
      <div class="modalContent">
        <div @click="downloadModal = false" class="t-right">
          <img src="/assets/images/kreuz-petrol.svg" style="cursor: pointer;vertical-align: -5px; margin-right: 24px; margin-top: 24px; width: 16px;">
        </div>
        <div class="t-center pad-8">
          <h3 class="color-petrol f-normal">
            Download
          </h3>
        </div>
        <div class="t-center pad-8 centerAll" v-if="!downloadFile">
          <div class="loaderCon">
            <div class="slider">
              <div class="sliderInner" :style="{width: downloadPercent + '%'}" />
              <p class="sliderPercent">
                {{ downloadPercent }}%
              </p>
            </div>
            <div class="border">
              <div class="borderLeft" />
              <div class="borderRight" />
              <div class="borderFill" />
            </div>
          </div>
        </div>
        <div class="t-center pad-8 centerAll" v-else>
          <div>
            <img :src="downloadFile" style="max-width: 200px; max-height: 200px" v-if="!downloadFileName.endsWith('.zip')">
          </div>
        </div>
        <div class="t-center">
          <a :href="downloadFile" :download="downloadFileName" class="button bgcolor-mint color-petrol image right">Datei herunterladen</a>
        </div>
        <div class="pad-32 t-center">
          <button class="button bgcolor-orange color-white fluid image left" @click="$router.push('/dashboard')">
            <img style="width: 7px; filter: brightness(100); vertical-align: -1px;" src="/assets/images/pfeil-links-petrol.svg"> Zurück zum Dashboard
          </button>
          <div class="pad-4" />
          <button class="button bgcolor-mint color-petrol fluid image right" @click="number(1);" v-if="images.currentIndex + 1 < images.files.length">
            Weiter zum nächsten Bild <img style="margin-right: 5px; width: 7px; vertical-align: -1px;" src="/assets/images/pfeil-rechts-petrol.svg">
          </button>
          <div class="pad-16"></div>
          <a href="https://www.easycrop.de/feedback/" target="_blank" style="text-decoration: none; color: #00000050">Feedback an easyCROP senden</a>
        </div>
      </div>
    </div>
    <VueModal :show="modalFlat" approve="ok" @approve="modalFlat = false" @close="modalFlat = false">
      Deine Tagesflat ist nun aktiv.
    </VueModal>
    <div class="vueModal" v-if="modalDelete">
      <div class="vueModalOuter" @click="modalDelete = false" />
      <div class="vueModalInner">
        <div class="pad-16" />
        <div class="t-center pad-8">
          <h3>Möchtest du das Bild wirklich löschen?</h3>
        </div>
        <div class="pad-32 t-right">
          <button style="margin-right: 8px;" class="button bgcolor-mint circular color-black" @click="modalDelete = false">
            Abbrechen
          </button>
          <button class="button bgcolor-petrol circular color-white" @click="deleteImage">
            Bild löschen
          </button>
        </div>
      </div>
    </div>
    <div class="vueModal" v-if="notEnoughCredits">
      <div class="vueModalOuter" @click="notEnoughCredits = false" />
      <div class="vueModalInner">
        <div class="pad-16" />
        <div class="t-center pad-8">
          <h3>Du hast nicht genügend Credits.</h3>
        </div>
        <div class="pad-32 t-right">
          <button style="margin-right: 8px;" class="button bgcolor-mint circular color-black" @click="notEnoughCredits = false">
            Abbrechen
          </button>
          <router-link class="button bgcolor-petrol circular color-white" :to="{name: 'Profile', params: {page: 'creditkonto'}}">
            Neue kaufen
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="loadingStart">
      <div class="focusloader">
        <img src="/assets/images/Image-zuschneiden-petrol.svg">
        <h5 class="color-petrol">
          Lädt...
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {imageStore} from "@/store/imageStore";
import preview from "@/components/preview";
import Cropper from "cropperjs";
import JSZip from "jszip";
import {jsonToBinary} from "@/modules/exif";
import {dataURItoBlob} from "@/modules/main";
import SliderBar from "@/components/sliderBar";
import helpbox from "@/components/basic/helpbox";

export default {
  name: "Edit",
  title: "Bearbeiten",
  components: {
    SliderBar,
    'canvasPreview': preview,
    'helpbox': helpbox,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const images = imageStore()

    return {auth, config, user, images}
  },
  data(){
    return {
      cropper: false,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      scaleX: parseInt(this.images.currentImage.width),
      scaleY: parseInt(this.images.currentImage.height),
      presetName: '',
      presetSaved: false,
      step: this.$route.params.step ?? 1,
      blob: '',
      loading: true,
      factor: 0,
      selectedPreset: -1,
      addToPreset: -2,
      loadingStart: true,
      presetRadio: null,
      previewType: 'manuell',
      noTrigger: false,
      info: false,
      wrongChar:false,
      downloadPercent: 100,
      downloadModal: false,
      newPreset: false,
      changedValues: false,
      modalDelete: false,
      hint: false,
      currentName: this.images.name,
      lock: true,
      readyCount: 0,
      notEnoughCredits: false,
      currentQuery: null,
      modalFlat: false,
      downloadFileName: null,
      downloadFile: null
    }
  },
  computed: {
    calcPattern(){
      if(this.presetRadio == null) return {}
      const imgData = this.cropper.getImageData()
      const tx = parseInt(this.presetRadio.split('-')[0])
      const ty = parseInt(this.presetRadio.split('-')[1])
      const tw = imgData.naturalWidth
      const th = imgData.naturalHeight
      let rw = 0
      let rh = 0
      if(tx/ty > tw/th){
        rw = tw
        rh = tw / tx * ty
      }else{
        rw = th * tx / ty
        rh = th
      }
      return {width: Math.round(rw), height: Math.round(rh)}
    },
    costs() {
      try {
        const cost = this.previewType === 'list' ? this.images.presetsByID(this.selectedPreset)['pattern'].length : 1
        if(cost >  50 - this.user.todayCreditsUsed){
          return {credits: 50 - this.user.todayCreditsUsed, flat: true, string: 50 - this.user.todayCreditsUsed + (50 - this.user.todayCreditsUsed === 1 ? ' Credit' : ' Credits')}
        }else{
          return {credits: cost, flat: false, string: cost + (cost === 1 ? ' Credit' : ' Credits')}
        }
      }catch (e) {
        return ''
      }
    },
    dayEnd() {
      const date = new Date()
      const dateBerlin = date.toLocaleString("en", {timeZone: 'Europe/Berlin', year: 'numeric', day: 'numeric', month: 'short'})
      const offsetBerlin = date.toLocaleString("en", {timeZone: 'Europe/Berlin', timeStyle: "long"}).split(" ").slice(-1)[0].replace('GMT', '')

      var timeStart = new Date();
      var timeEnd = new Date(Date.parse(dateBerlin + ' 23:59:59 GMT'+offsetBerlin));

      var difference = timeEnd - timeStart;
      var diff_result = new Date(difference);

      var hourDiff = diff_result.getHours();
      return hourDiff;
    }
  },
  watch: {
    selectedPreset(){
      if(!this.noTrigger) {
        const _this = this
        this.changedValues = true
        this.noTrigger = true
        this.scaleY = null
        this.scaleX = null
        this.presetRadio = null
        this.previewType = "list"
        this.$nextTick(function (){
          _this.noTrigger = false
        })
      }
    },
    scaleX(){
      if(!this.noTrigger) {
        this.changedValues = true

        if(/[^0-9 ]/g.test(this.scaleX)){
          this.wrongChar = true
          this.scaleX =  this.scaleX.replace(/[^0-9 ]/g, '') !== '' ? parseInt(this.scaleX.replace(/[^0-9 ]/g, '')) : 1
        }else{
          this.wrongChar = false
        }

        if(this.scaleX > 10000){
          this.scaleX = 10000
          this.info = true
        }
        const _this = this
        this.noTrigger = true
        this.presetSaved = false
        this.presetRadio = null
        this.selectedPreset = -1
        this.previewType = "manuell"
        this.$nextTick(function (){
          _this.noTrigger = false
        })
      }
    },
    scaleY(){
      if(!this.noTrigger) {
        this.changedValues = true

        if(/[^0-9 ]/g.test(this.scaleY)){
          this.wrongChar = true
          this.scaleY = this.scaleY.replace(/[^0-9 ]/g, '') !== '' ? parseInt(this.scaleY.replace(/[^0-9 ]/g, '')) : 1
        }else{
          this.wrongChar = false
        }

        if(this.scaleY > 10000){
          this.scaleY = 10000
          this.info = true
        }
        const _this = this
        this.noTrigger = true
        this.presetSaved = false
        this.presetRadio = null
        this.selectedPreset = -1
        this.previewType = "manuell"
        this.$nextTick(function (){
          _this.noTrigger = false
        })
      }
    },
    presetRadio(){
      if(!this.noTrigger){
        this.changedValues = true

        const _this = this
        this.noTrigger = true
        this.scaleY = null
        this.scaleX = null
        this.selectedPreset = -1
        this.previewType = "radio"
        const cord = this.calcPattern
        this.scaleX = cord.width
        this.scaleY = cord.height
        this.$nextTick(function (){
          _this.noTrigger = false
        })
      }
    },
    addToPreset(){
      this.presetSaved = false
    }
  },
  mounted() {
    const _this = this
    if(this.images.currentIndex < 0 || this.images.currentImage === undefined) {
      this.$router.push('/dashboard')
    }else{
      this.images.loadBinaryImage(this.images.currentIndex, 'big')
        .then(() => {
          _this.initCropper()
        })
        .finally(() => {
          _this.config.loading = false
        })

      this.images.$subscribe((mutation, state) => {
        if (state.name !== _this.currentName) {
          _this.currentName = state.name
          _this.$nextTick(() => {
            _this.number()
          })
        }
      })
    }
  },
  methods: {
    initCropper(){
      const _this = this
      _this.loading = false
      _this.$nextTick(() => {
        _this.cropper = new Cropper(_this.$refs['image'], {
          viewMode: 1,
          zoomable: false,
          minContainerHeight  : 100,
          minContainerWidth   : 100,
          maxContainerHeight  : 800,
          maxContainerWidth   : 800,
          crop(event) {
            _this.width = Math.round(event.detail.width*_this.factor)
            _this.height = Math.round(event.detail.height*_this.factor)
            _this.x = Math.round(event.detail.x*_this.factor)
            _this.y = Math.round(event.detail.y*_this.factor)
          },
          cropend(event) {
            _this.saveFocus()
          },
          ready() {
            _this.factor = _this.images.currentImage.width/_this.cropper.getImageData().naturalWidth
            let obj = _this.images.currentFocus
            if(typeof obj != 'undefined' && Object.keys(obj).length > 0){
              if(obj.width > 0){
                _this.width = Math.max(obj.width/_this.factor, 100/_this.factor)
                _this.height = Math.max(obj.height/_this.factor, 100/_this.factor)
                _this.x = obj.x/_this.factor
                _this.y = obj.y/_this.factor
                _this.changeCropper()
              }else if(obj.face.length > 5){
                _this.calcFace(JSON.parse(obj.face))
              }
              if(this.step === 2){
                _this.next()
              }
            }
            _this.loadingStart = false
          }
        });
      })
    },
    changeCropper(){
      let data = {
        x: parseInt(this.x),
        y: parseInt(this.y),
        width: parseInt(this.width),
        height: parseInt(this.height),
        rotate: 0,
        scaleX: 1,
        scaleY: 1
      }
      this.cropper.setData(data)
    },
    calcFace(faces, resize = 2){
      if(faces.length < 1) return
      let x1 = 99999
      let x2 = 0
      let y1 = 99999
      let y2 = 0
      for(let i in faces){
        const face = faces[i]
        x1 = face.x < x1 ? face.x : x1
        x2 = face.x + face.w > x2 ? face.x + face.w : x2
        y1 = face.y < y1 ? face.y : y1
        y2 = face.y + face.h > y2 ? face.y + face.h : y2
      }
      const resizeX = (x2 - x1)*(resize-1)/2
      const resizeY = (y2 - y1)*(resize-1)/2

      this.x = (x1-resizeX)/this.factor
      this.width = (x2 - x1 + resizeX*2)/this.factor
      this.y = (y1 - resizeY)/this.factor
      this.height = (y2 - y1 + resizeY*3)/this.factor

      this.width = Math.max(this.width, 30)
      this.height = Math.max(this.height, 30)

      if(this.x < 0) this.x = 0
      if(this.width > this.images.currentImage.width) this.width = this.images.currentImage.width
      if(this.y < 0) this.y = 0
      if(this.height > this.images.currentImage.height) this.height = this.images.currentImage.height


      this.changeCropper()
    },
    swap(){
      const temp = this.scaleX
      this.scaleX = this.scaleY
      this.scaleY = temp
    },
    savePreset() {
      if(this.presetSaved) return
      const _this = this
      const fD = new FormData();
      fD.append('id', this.addToPreset)
      fD.append('name', this.presetName)
      fD.append('width', this.scaleX)
      fD.append('height', this.scaleY)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'Formatsets/addPattern',fD)
          .then(function(response){
            if(response.status === 250) {
              _this.presetSaved = true
              if (_this.addToPreset == -1) {
                _this.images.presets.push({
                  id: response.data.id,
                  name: response.data.name,
                  pattern: [{id: 999, width: _this.scaleX, height: _this.scaleY}]
                })
                _this.newPreset = true
                _this.selectedPreset = response.data.id
                _this.addToPreset = response.data.id
              }else{
                for(let key = 0; key < _this.images.presets.length; key++){
                  if(_this.images.presets[key].id == _this.addToPreset){
                    _this.images.presets[key].pattern.push({id: _this.addToPreset, width: _this.scaleX, height: _this.scaleY})
                  }
                }
                _this.selectedPreset = _this.addToPreset
                _this.addToPreset = -2
              }
              _this.hint = true
              setTimeout(() => {_this.hint = false}, 3500)
            }else if(response.status === 249){
              _this.presetSaved = true
              _this.selectedPreset = _this.addToPreset
            }
          })
    },
    noNegative(evt){
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      // 0-9 auf Tastatur + 0-9 auf Nummernblock + Tab + pfeiltasten
      if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || charCode == 9 || charCode == 8 || (charCode >= 37 && charCode <= 41)) {
        return true
      }
      else {
        evt.preventDefault()
      }
    },
    next(save = true){
      const _this = this

      if(!this.changedValues) {
        this.scaleX = parseInt(this.images.currentImage.width)
        this.scaleY = parseInt(this.images.currentImage.height)
        this.presetName = ''
        this.selectedPreset = -1
        this.presetRadio = null
        this.previewType = 'manuell'
        this.presetSaved = false
      }

      if(save) this.saveFocus()
      this.step = 2
      this.cropper.disable();
    },
    back(step = 1){
      if(this.step > 1 && step === 1){
        this.step = step
        this.$nextTick(() => {
          this.cropper.destroy();
          this.initCropper()
        })
      }else if(step === 0){
        this.$router.push('/dashboard')
      }
    },
    saveFocus(number = 0){
      if(this.step == 2) return
      let img = this.images.currentImage.path
      const fD = new FormData();
      fD.append('src', img)
      fD.append('width', this.width)
      fD.append('height', this.height)
      fD.append('x', this.x)
      fD.append('y', this.y)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'Formatsets/setFocus',fD)
          .then(r => {
          })
      this.images.focus[img].height= this.height
      this.images.focus[img].width= this.width
      this.images.focus[img].x= this.x
      this.images.focus[img].y= this.y
      if(number !== 0) this.images.number(number)
    },
    download(){
      const _this = this
      this.downloadFileName = null
      this.downloadFile = null
      if(this.lock) {
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        fD.append('credits', this.previewType === 'list' ? this.images.presetsByID(this.selectedPreset)['pattern'].length : 1)
        fD.append('focus', this.images.currentFocus.id)
        fD.append('preset', this.selectedPreset)
        this.axios.post(this.config.projectURL+'dashboard/checkCredits', fD)
            .then(response => {
              console.log(response.data)
              if(response.status === 250){
                _this.currentQuery = response.data
                _this.lock = false
              }else if(response.status === 251){
                _this.notEnoughCredits = true
              }
            })
            .catch(e => {
              console.log(e)
            })
      }else{
        //const link = document.createElement('a');
        let ext = this.images.currentFocus.src.split('.')
        ext = ext[ext.length - 1]
        setTimeout(() => this.fakeDownload(), 500)
        //link.download = this.images.currentFocus.origin+'_'+this.scaleX+'x'+this.scaleY+'.'+ext
        this.downloadFileName = this.images.currentFocus.origin+'_'+this.scaleX+'x'+this.scaleY+'.'+ext
        this.$nextTick(() => {
          const fD = new FormData();
          fD.append('sessionID', this.auth.sessionID)
          fD.append('query', this.currentQuery)
          this.axios.post(this.config.projectURL + 'dashboard/finishQuery', fD)
              .then(async r => {
                console.log(r)
                if (r.status === 250) {
                  _this.modalFlat = _this.user.todayCreditsUsed < 50 && r.data.todayCreditsUsed >= 50
                  _this.user.credits = r.data.credits
                  _this.user.todayCreditsUsed = r.data.todayCreditsUsed
                  if (ext === 'jpg' || ext === 'jpeg') {
                    //link.href = jsonToBinary(_this.images.currentFocus.exif, _this.$refs.singlecanvas.canvasToDataUri(ext))
                    //link.click();
                    _this.downloadFile = jsonToBinary(_this.images.currentFocus.exif, _this.$refs.singlecanvas.canvasToDataUri(ext))
                  } else if (ext === 'gif') {
                    _this.downloadFile = URL.createObjectURL(await _this.images.toGifBlob(_this.$refs.singlecanvas.getRaw()))
                  } else {
                    //link.href = _this.$refs.singlecanvas.canvasToDataUri(ext)
                    //link.click();
                    _this.downloadFile = _this.$refs.singlecanvas.canvasToDataUri(ext)
                  }
                  _this.lock = true
                }
              })
              .catch(e => console.log(e))
        })
      }
    },
    startZip(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('credits', this.previewType === 'list' ? this.images.presetsByID(this.selectedPreset)['pattern'].length : 1)
      fD.append('focus', this.images.currentFocus.id)
      fD.append('preset', this.selectedPreset)
      this.axios.post(this.config.projectURL+'dashboard/checkCredits', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              _this.currentQuery = response.data
              _this.readyCount = 0;
              _this.lock = false;
            }else if(response.status === 251){
              _this.notEnoughCredits = true
            }
          })
    },
    addReady(){
      this.readyCount++;
      if(this.readyCount === this.images.presetsByID(this.selectedPreset)['pattern'].length)this.downloadZip()
    },
    fakeDownload(){
      this.downloadModal = true
      this.downloadPercent = 0
      const _this = this
      const interval = setInterval(function (){
        if(_this.downloadPercent === 100) {
          clearInterval(interval)
          return
        }
        _this.downloadPercent += 2
      }, 10)
    },
    async downloadZip(){
      const _this = this
      this.downloadFileName = null
      this.downloadFile = null
      this.downloadPercent = 0
      this.downloadModal = true
      if(typeof this.$refs.multicanvas == 'undefined' || this.$refs.multicanvas.length === 0) return

      let ext = this.images.currentFocus.src.split('.')
      ext = ext[ext.length - 1]
      if(ext !== 'gif')_this.fakeDownload()
      const name = this.images.currentFocus.origin+'_'+this.images.presetsByID(this.selectedPreset).name+'_';
      const zip = new JSZip();
      this.downloadModal = true
      for(let key in this.$refs.multicanvas){
        let dim = this.$refs.multicanvas[key].getDimension()
        const preName = dim.name !== '' && dim.name != null ? name + dim.name + '_' : name;
        if(ext === 'jpg' || ext === 'jpeg'){
          zip.file(preName+dim.width+'x'+dim.height+'.'+ext, dataURItoBlob(jsonToBinary(this.images.currentFocus.exif, this.$refs.multicanvas[key].canvasToDataUri(ext))))
        }else if(ext === 'gif'){
          let blob = await this.images.toGifBlob(this.$refs.multicanvas[key].getRaw())
          this.downloadPercent = Math.round(key / (this.$refs.multicanvas.length - 1) * 100)
          zip.file(preName+dim.width+'x'+dim.height+'.'+ext, blob)
        }else{
          zip.file(preName+dim.width+'x'+dim.height+'.'+ext, dataURItoBlob(this.$refs.multicanvas[key].canvasToDataUri(ext)))
        }
      }
      zip.generateAsync({type:"base64"})
          .then(function(content) {
            _this.downloadFileName = _this.images.currentFocus.origin+'_'+_this.images.presetsByID(_this.selectedPreset).name+'.zip';
            _this.downloadFile = "data:application/zip;base64," + content;
            const fD = new FormData();
            fD.append('sessionID', _this.auth.sessionID)
            fD.append('query', _this.currentQuery)
            _this.axios.post(_this.config.projectURL + 'dashboard/finishQuery', fD)
                .then(r => {
                  if(r.status === 250) {
                    _this.modalFlat = _this.user.todayCreditsUsed < 50 && r.data.todayCreditsUsed >= 50
                    _this.user.credits = r.data.credits
                    _this.user.todayCreditsUsed = r.data.todayCreditsUsed
                  }
                })
                .catch(e => console.log(e))
          });
      this.lock = true
    },
    number(count = 0){
      const _this = this

      this.changedValues = false
      this.loadingStart = true
      this.downloadModal = false
      if(count != 0) this.images.number(count)
      this.images.loadBinaryImage(this.images.currentIndex, 'big')
        .then(() => {
          this.$nextTick(() => {
            if(this.step == 2){
              this.step = 1

              this.$nextTick(() => {
                this.cropper.destroy()
                this.initCropper()
                this.next(false)
              })
            }else{
              this.cropper.destroy()
              this.initCropper()
            }
          })
        })
        .finally(() => {
          _this.loadingStart = false
        })
    },
    deleteImage(){
      const _this = this
      this.images.deleteImage()
        .then(() => {
          if(this.images.currentIndex < 0) this.$router.push('/dashboard')
          _this.modalDelete = false
          _this.cropper.destroy()
          _this.initCropper()
        })
    }
  }
}
</script>

<style>
.blob{
  max-height: 500px;
}
canvas.preview{
  background-color: white;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
}
p.preview{
  text-align: center;
  margin-bottom: 5px;
}
div.preview{
  margin: 10px 0;
}
div.previewContainer{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.focusloader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  z-index: 3;
  text-align: center;
  padding-top: 30vh;
}
.focusloader img{
  max-width: 100px;
}
#BearbeitenApp .cropper-dashed{
  border: none!important;
}
.bildmaß{
  max-height: 50px;
  max-width: 50px;
}
.bild90{
  -webkit-transform:rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
td{
  text-align: center;
  padding: 5px;
}
.attention{
  max-width: 30px;
}
.attention.left{
  float: left;
}
.attention.right{
  float: right;
}
.mh80{
  max-height: 80vh;
}
.progressHeader{
  width: 100%;
  height: 44px;
  margin: 5px 0 0 0;
  color: white;
}
.progressHeader .step{
  width: 33.33%;
  height: 100%;
  background-color: #909090;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.progressHeader .step .stepbutton{
  display: inline;
  padding: 6px 8px;
  border-radius: 4px;
}
.progressHeader .icon{
  height: 20px;
  vertical-align: top;
  margin-right: 8px;
  display: inline;
}
.progressHeader p{
  display: inline;
  font-weight: bold;
}
.progressHeader .icon.home{
  filter: invert();
}
.progressHeader .done{
  background-color: #909090 !important;
  cursor: pointer;
}
.progressHeader .step:not(.current){
  cursor: pointer;
}

.progressHeader .current{
  background-color: #ff856e !important;
}
.progressHeader .current:last-of-type:after{
  display: none;
}
.progressHeader .done::after {
  border-color: transparent transparent transparent #909090;
}
.progressHeader .current::after{
  border-color: transparent transparent transparent #ff856e;
}
.loaderCon{
  width: 50%;
  height: 44px;
  position: relative;
}
.slider{position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 18px);
}
.sliderInner{
  height: 100%;
  width: 0%;
  background-color: #def7f1;
}
.border{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
}
.border .borderLeft{
  background: no-repeat url("../../public/assets/images/icons/crop-left.png");
  background-size: contain;
  height: 100%;
  width: 15px;
  float: left;
}
.border .borderRight{
  background: no-repeat url("../../public/assets/images/icons/crop-right.png");
  background-size: contain;
  height: 100%;
  width: 15px;
  float: right;
}
.border .borderFill{
  background: repeat-x url("../../public/assets/images/icons/crop_fill.png");
  background-size: contain;
  height: 100%;
  margin: 0 12px;
}
p.sliderPercent{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #135969;
}
.centerAll{
  display: flex;
  align-items: center;
  justify-content: center;
}
#swapIcon{
  height: 15px;
  margin-top: 5px;
}
.newPreset option:last-child
{
  color: #ff8000;
  font-weight: 600;
}

.pulse-hint {
  position: absolute;
  top: -4px;
  left: -24px;
  display: block;
  width: 35px;
  height: 35px;
  border: none;
  cursor: pointer;
  animation: pulse 1.5s infinite;
  float: left;
}

@keyframes pulse {
  0% {
    transform: scale(.5);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(.5);
  }
}
@media (max-width: 768px) {
  .progressHeader{
    display: block;
  }
  .progressHeader .step{
    width: 100%;
  }
  .fullBar{
    clear: both;
  }
  #deleteButton{
    float: right;
    margin-top: 8px;
  }
  p.countText{
    margin-top: 4px;
    float: left;
  }
}
</style>