<template>
  <transition-group name="modal">
    <div class="pad-8" v-if="config.tutorial">
      <transition name="collapse">
        <div id="helpBox">
          <section class="display-Dektop">
            <div class="helpBoxHeader grid-12 bgcolor-mint">
              <div class="col-sp-12 col-sd-3 row-sp-12">
                <button class="tabButton" @click="tab = 3" :class="tab == 3 ? 'active' : ''">
                  Wie benutze ich easyCROP?
                </button>
              </div>
              <div class="col-sp-12 col-sd-3 row-sp-12">
                <button class="tabButton" @click="tab = 2" :class="tab == 2 ? 'active' : ''">
                  Wie nutze ich Format-Sets?
                </button>
              </div>
              <!--
              <div class="col-sp-12 col-sd-5 row-sp-12">
                <button class="tabButton" @click="tab = 3" :class="tab == 3 ? 'active' : ''">
                  Wie kann ich mehrere Bilder auf einmal zuschneiden?
                </button>
              </div>
              -->
              <div class="col-sp-12 col-sd-6 row-sp-12 t-right pad-8">
                <a @click="this.config.tutorial = false;">
                  <img src="assets/images/kreuz-petrol.svg" style="cursor: pointer; width: 16px;"/>
                </a>
              </div>
            </div>
            <div class="bgcolor-white pad-24" v-if="tab == 1">
              <p class="color-petrol">
                easyCROP ist ein sicheres Online-Tool, mit dem du deine Bilder schnell und einfach zuschneiden kannst.<br>
                Die folgende Anleitung hilft dir dabei:
              </p>
              <div class="pad-16"></div>
              <div class="grid-5">
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">1</span> Bild hochladen
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">2</span> Fokusbereich optimieren
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">3</span> Bildformat wählen
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">4</span> Zuschnitt downloaden
                  </h3>
                </div>
              </div>
              <div class="grid-5">
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Tab-01-easycrop-Hilfebox-01.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Tab-01-easycrop-Hilfebox-02.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Tab-01-easycrop-Hilfebox-03.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Tab-01-easycrop-Hilfebox-04.jpg" class="responsive-img"/>
                </div>
              </div>
              <div class="pad-16"></div>
              <a class="button br-8 bgcolor-orange color-white" href="https://www.easycrop.de/blog/" target="_blank">
                <img src="assets/images/info-im-kreis-petrol.svg" style="vertical-align: -2px;width: 16px; filter: brightness(100)"/> Mehr erfährst du in unserem Blog
              </a>
            </div>
            <div class="bgcolor-white pad-24" v-if="tab == 2">
              <p class="color-petrol">
                Mit einem Format-Set kannst du eine individuelle Gruppe aus mehreren Bildformaten anlegen.<br>
                Auf dieses kannst du beim Zuschneiden immer wieder zugreifen, sodass du deine Bilder in einem Rutsch in alle benötigten Formate bringen kannst.<br>
                Die folgende Anleitung zeigt dir, wie du ein Format-Set erstellst:
              </p>
              <div class="pad-16"></div>
              <div class="grid-5">
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">1</span> Zu den Format-Sets wechseln
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">2</span> Neues Format-Set erstellen
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">3</span> Format-Set benennen
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">4</span> Bildmaße eingeben
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">5</span> Einstellungen speichern
                  </h3>
                </div>
              </div>
              <div class="grid-5">
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-01-Format-Set.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-02-Format-Set.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-03-Format-Set.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-04-Format-Set.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-05-Format-Set.jpg" class="responsive-img"/>
                </div>
              </div>
              <div class="pad-16"></div>
              <a class="button br-8 bgcolor-orange color-white" href="https://www.easycrop.de/blog/" target="_blank">
                <img src="assets/images/info-im-kreis-petrol.svg" style="vertical-align: -2px;width: 16px; filter: brightness(100)"/> Mehr erfährst du in unserem Blog
              </a>
            </div>
            <div class="bgcolor-white pad-24" v-if="tab == 3">
              <p class="color-petrol">
                Um die Stapelverarbeitung nutzen zu können, benötigst du ein eigenes Format-Set, in dem deine Bildformate gespeichert sind.<br>
                Wie du ein Format-Set erstellst, zeigen wir dir im zweiten Tab: <a class="color-orange" @click="tab = 2">Wie nutze ich Format-Sets?</a><br>
                Befolge nun die folgende Anleitung, um deine Bilder in einem Rutsch zuzuschneiden:
              </p>
              <div class="pad-16"></div>
              <div class="grid-5">
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">1</span> Bilder hochladen
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">2</span> Fokusbereich optimieren
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">3</span> Gewünschte Bilder markieren
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">4</span> Format-Set auswählen
                  </h3>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <h3 class="color-petrol t-center">
                    <span class="number">5</span> Bilder runterladen
                  </h3>
                </div>
              </div>
              <div class="grid-5">
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-01-Stapelverarbeitung.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-02-Stapelverarbeitung.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-03-Stapelverarbeitung.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-04-Stapelverarbeitung.jpg" class="responsive-img"/>
                </div>
                <div class="col-sp-5 col-sd-1 row-sp-5">
                  <div class="pad-8"></div>
                  <img src="assets/images/helpbox/Slide-05-Stapelverarbeitung.jpg" class="responsive-img"/>
                </div>
              </div>
              <div class="pad-16"></div>
              <a class="button br-8 bgcolor-orange color-white" href="https://www.easycrop.de/blog/" target="_blank">
                <img src="assets/images/info-im-kreis-petrol.svg" style="vertical-align: -2px;width: 16px; filter: brightness(100)"/> Mehr erfährst du in unserem Blog
              </a>
            </div>
          </section>
          <section class="display-Mobile">
            <div class="helpBoxHeader grid-12">
              <div class="col-sp-12 row-sp-12 t-right pad-8">
                <a @click="this.config.tutorial = false;" style="cursor: pointer;">
                  Schließen <img src="assets/images/kreuz-petrol.svg" style="vertical-align: -2px; width: 16px;"/>
                </a>
              </div>
              <div class="col-sp-12 row-sp-12">
                <button class="tabButton" @click="tab = 3" :class="tab == 3 ? 'active' : ''">
                  Wie benutze ich easyCROP?
                </button>
                <div class="bgcolor-white pad-24" v-if="tab == 1">
                  <p class="color-petrol">
                    easyCROP ist ein sicheres Online-Tool, mit dem du deine Bilder schnell und einfach zuschneiden kannst.<br>
                    Die folgende Anleitung hilft dir dabei:
                  </p>
                  <slider-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">1</span> Bilder hochladen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-01-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">2</span> Fokusbereich optimieren
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-02-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">3</span> Gewünschte Bilder markieren
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-03-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">4</span> Format-Set auswählen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-04-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">5</span> Bilder runterladen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-05-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                  </slider-info>
                  <div class="pad-16"></div>
                  <a class="button br-8 bgcolor-orange color-white" href="https://www.easycrop.de/blog/" target="_blank">
                    <img src="assets/images/info-im-kreis-petrol.svg" style="vertical-align: -2px;width: 16px; filter: brightness(100)"/> Mehr erfährst du in unserem Blog
                  </a>
                </div>
              </div>
              <div class="col-sp-12 row-sp-12">
                <div class="pad-4"></div>
                <button class="tabButton" @click="tab = 2" :class="tab == 2 ? 'active' : ''">
                  Wie nutze ich Format-Sets?
                </button>
                <div class="bgcolor-white pad-24" v-if="tab == 2">
                  <p class="color-petrol">
                    Mit einem Format-Set kannst du eine individuelle Gruppe aus mehreren Bildformaten anlegen.<br>
                    Auf dieses kannst du beim Zuschneiden immer wieder zugreifen, sodass du deine Bilder in einem Rutsch in alle benötigten Formate bringen kannst.<br>
                    Die folgende Anleitung zeigt dir, wie du ein Format-Set erstellst:
                  </p>
                  <slider-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">1</span> Zu den Format-Sets wechseln
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-01-Format-Set.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">2</span> Neues Format-Set erstellen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-02-Format-Set.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">3</span> Format-Set benennen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-03-Format-Set.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">4</span> Bildmaße eingeben
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-04-Format-Set.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">5</span>  Einstellungen speichern
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-05-Format-Set.jpg" class="responsive-img"/>
                    </miniature-info>
                  </slider-info>
                  <div class="pad-16"></div>
                  <a class="button br-8 bgcolor-orange color-white" href="https://www.easycrop.de/blog/" target="_blank">
                    <img src="assets/images/info-im-kreis-petrol.svg" style="vertical-align: -2px;width: 16px; filter: brightness(100)"/> Mehr erfährst du in unserem Blog
                  </a>
                </div>
              </div>
              <div class="col-sp-12 row-sp-12">
                <div class="pad-4"></div>
                <!--
                <button class="tabButton" @click="tab = 3" :class="tab == 3 ? 'active' : ''">
                  Wie kann ich mehrere Bilder auf einmal zuschneiden?
                </button>
                -->
                <div class="bgcolor-white pad-24" v-if="tab == 3">
                  <p class="color-petrol">
                    Um die Stapelverarbeitung nutzen zu können, benötigst du ein eigenes Format-Set, in dem deine Bildformate gespeichert sind.<br>
                    Wie du ein Format-Set erstellst, zeigen wir dir im zweiten Tab: <a class="color-orange" @click="tab = 2">Wie nutze ich Format-Sets?</a><br>
                    Befolge nun die folgende Anleitung, um deine Bilder in einem Rutsch zuzuschneiden:
                  </p>
                  <slider-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">1</span> Bilder hochladen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-01-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">2</span> Fokusbereich optimieren
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-02-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">3</span> Gewünschte Bilder markieren
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-03-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">4</span> Format-Set auswählen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-04-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                    <miniature-info>
                      <h3 class="color-petrol t-center">
                        <span class="number">5</span> Bilder runterladen
                      </h3>
                      <div class="pad-8"></div>
                      <img src="assets/images/helpbox/Slide-05-Stapelverarbeitung.jpg" class="responsive-img"/>
                    </miniature-info>
                  </slider-info>
                  <div class="pad-16"></div>
                  <a class="button br-8 bgcolor-orange color-white" href="https://www.easycrop.de/blog/" target="_blank">
                    <img src="assets/images/info-im-kreis-petrol.svg" style="vertical-align: -2px;width: 16px; filter: brightness(100)"/> Mehr erfährst du in unserem Blog
                  </a>
                </div>
              </div>

            </div>
          </section>
        </div>
      </transition>
    </div>
  </transition-group>
</template>
<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import SliderInfo from "@/components/sliderInfo";
import MiniatureInfo from "@/components/miniatureInfo";

export default {
  name: "HelpBox",
  components: {MiniatureInfo, SliderInfo},
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      tab: 3,
    }
  },
  mounted() {
  },
  methods: {

  },
}
</script>

<style scoped>
.helpBoxHeader{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#helpBox .bgcolor-white{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.display-Mobile .helpBoxHeader button{
  border-radius: 12px;
}
.display-Mobile .helpBoxHeader button.active{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.helpBoxHeader button{
  background-color: #def7f1;
  color: #135969;
  border: none;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  display: block;
}
.helpBoxHeader button.active{
  background-color: #135969;
  color: #ffffff;
}
span.number{
  border: #135969 2px solid;
  vertical-align: 1px;
  line-height: 12px;
  text-align: center;
  border-radius: 90%;
  display: inline-block;
  padding: 4px 5px;
}
.helpBoxHeader .miniCon{
  background-color: transparent!important;
  padding-top: 16px;
}
</style>