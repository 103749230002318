import { defineStore } from 'pinia'

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        firstname: '',
        lastname: '',
        email: '',
        avatar: '',
        position: '',
        company: '',
        country: '',
        credits: 0,
        todayCreditsUsed: 0
    })
})