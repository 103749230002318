<template>
  <div class="fullBar">
    <div class="arrowImageCon float-left" @click="number(-1)">
      <div class="arrowImage" :class="{arrowLeft: leftArrow}"/>
    </div>
    <div class="innerConBar" ref="inner">
      <div class="miniConBar" :style="{left: left + 'px', transition: transition ? 'all 1s ease' : 'none'}" ref="mini" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
        <slot></slot>
      </div>
    </div>
    <div class="arrowImageCon float-right" @click="number(1)">
      <div class="arrowImage" :class="{arrowRight: rightArrow}"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "SliderInfo",
  data() {
    return {
      left: 0,
      offsetLeft: 0,
      offsetWidth: 0,
      transition: false,
      leftArrow: false,
      rightArrow: true,
      count: 0,
      amount: 0,
      xTouchStart: 0,
      xTouchMove: 0
    }
  },
  mounted() {
    this.amount = this.$refs.mini.childElementCount
    window.onresize = () => {this.number()}
  },
  methods: {
    number(count = 0){
      this.count = Math.min(this.amount - 1, Math.max(this.count + count, 0))
      this.leftArrow = this.count > 0
      this.rightArrow = this.count < this.amount - 1

      this.offsetLeft = (window.innerWidth*0.6 + 20) * this.count

      this.left = - this.offsetLeft
    },
    touchStart(e){
      this.xTouchStart = e.touches[0].screenX
    },
    touchMove(e){
      this.xTouchMove= e.touches[0].screenX
    },
    touchEnd(){
      if(Math.abs(this.xTouchStart - this.xTouchMove) < 25) return
      this.number(this.xTouchStart > this.xTouchMove ? 1 : -1)
    }
  }
}
</script>

<style scoped>
.fullBar{
  width: 100%;
  height: calc(100vw - 100px);
  position: relative;
}
.innerConBar{
  width: calc(100% - 80px);
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 40px;
}
.miniConBar{
  overflow:hidden;
  white-space: nowrap;
  position: absolute;
  left: 0px;
  top: 0px;
  /*width: 100%;*/
  height: 100%;
}
.arrowImage{
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.arrowLeft{
  background-image: url("../../public/assets/images/pfeil-links-petrol.svg");
}
.arrowRight{
  background-image: url("../../public/assets/images/pfeil-rechts-petrol.svg");
}
.arrowImageCon{
  width: 25px;
  height: 50px;
  margin: 10px;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.float-left{
  float: left;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}
.float-right{
  float: right;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}
</style>