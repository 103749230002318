<template>
  <main style="height: calc(100vh - 44px); margin-top: -80px; display: flex; justify-content: center; align-items: center;">
    <section v-if="step == 1" id="GetStarted" ref="GetStarted" tabindex="1" @keydown.enter.prevent="avatarModal = true; $nextTick(() => $refs.avatarModalRef.focus())" style="outline: none">
      <div class="content-500">
        <div class="t-center pad-32">
          <h2 class="f-normal">
            Willkommen bei
          </h2>
          <h1>easy<span class="f-normal">crop</span></h1>
        </div>
        <img id="avatar-selection" :src="'/assets/images/avatars/' + avatars[avatarIndex]" class="centered circular width-150 bgcolor-lightgrey is-square">
        <div class="t-center pad-16">
          <button class="button inverted bgcolor-black circular" @click="avatarModal = true; $nextTick(() => $refs.avatarModalRef.focus())">
            Wähle deinen Avatar
          </button>
          <div class="pad-4" />
          <button class="button bgcolor-petrol circular color-white" @click="$router.push({name: 'Register', params: {step: 2}})" v-if="next">
            Weiter ➔
          </button>
        </div>
      </div>
    </section>
    <section v-if="step == 2">
      <div class="content-500">
        <img :src="'/assets/images/avatars/' + avatars[avatarIndex]" class="centered circular width-150 bgcolor-lightgrey is-square">
        <div class="t-center pad-32">
          <h1 class="f-normal">
            Hallo!
          </h1>
          <h2>Wie dürfen wir dich nennen?</h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input type="text" ref="firstnameinput" name="firstname" placeholder="Vorname*" required v-model="firstname" maxlength="20" data-index="1" @keydown.enter.prevent="$refs.lastnameinput.focus()">
          </div>
          <div class="field simple pad-8">
            <input type="text" ref="lastnameinput" name="lastname" placeholder="Nachname*" required v-model="lastname" maxlength="20" data-index="2" @keydown.enter.prevent="$refs.companyinput.focus()">
          </div>
          <div class="field simple pad-8">
            <select ref="country" name="country" v-model="country" style="max-width: 316px;" required>
              <option value="-1" disabled>Land auswählen*</option>
              <option v-for="item in countrys" :key="item.iso" :value="item.iso">{{item.name}}</option>
            </select>
          </div>
          <div class="field simple pad-8">
            <input type="text" ref="companyinput" name="company" placeholder="Unternehmen (Optional)" v-model="company" maxlength="30" data-index="3" @keydown.enter.prevent="$refs.positioninput.focus()">
          </div>
          <div class="field simple pad-8">
            <input type="text" ref="positioninput" name="position" placeholder="Position (Optional)" v-model="position" maxlength="30" data-index="4" @keydown.enter.prevent="firstname.length > 0 && lastname.length > 0 ? $router.push({name: 'Register', params: {step: 3}}) : null">
          </div>
          <p class="feedbackmessage t-center pad-8 color-orange" v-if="firstname.length >= 20 || lastname.length >= 20">
            Vor- und/oder dein Nachname sind jeweils auf 20 Zeichen begrenzt.
          </p>
          <p class="feedbackmessage t-center pad-8 color-orange" v-if="company.length >= 30 || position.length >= 30">
            Unternehmensname und/oder Position sind jeweils auf 30 Zeichen begrenzt.
          </p>
        </div>
        <div class="pad-16" style="max-width: 320px; margin: 0 auto;">
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12">
              <button class="button bgcolor-petrol inverted color-petrol circular" @click="$router.push({name: 'Register', params: {step: 1}})">
                Zurück
              </button>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <button class="button bgcolor-petrol circular color-white" @click="$router.push({name: 'Register', params: {step: 3}})" v-if="firstname.length > 0 && lastname.length > 0 && country != -1">
                Weiter ➔
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="step == 3">
      <div class="content-500">
        <img :src="'/assets/images/avatars/' + avatars[avatarIndex]" class="centered circular width-150 bgcolor-lightgrey is-square">
        <div class="t-center pad-32">
          <h1 class="f-normal">
            Hallo {{ firstname }}!
          </h1>
          <h2>Gleich kann's losgehen!</h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input tabindex="1" type="email" name="email" placeholder="Deine E-Mail-Adresse*" required v-model="email" ref="email" @keydown.enter.prevent="$refs.pw1.focus()">
          </div>
          <div class="field simple pad-8">
            <input tabindex="2" id="registerPassword" type="password" name="password" placeholder="Passwort*" required v-model="password" ref="pw1" @keydown.enter.prevent="$refs.pw2.focus()">
            <div id="showPassword" @click="showPassword()">
              <svg id="showPasswordIcon" height="20px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/></svg>
              <svg id="hidePasswordIcon" height="20px" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/></svg>
            </div>
          </div>
          <div class="field simple pad-8">
            <input tabindex="3" id="registerPassword2" type="password" name="password2" placeholder="Passwort wiederholen*" required ref="pw2" v-model="password2" @keydown.enter.prevent="passwordCheck && emailCheck ? register() : null">
          </div>
          <p class="feedbackmessage t-center pad-8 color-orange" v-if="status === 'email'">
            Es gibt bereits einen Account mit dieser E-Mail-Adresse.
            <router-link to="/login" class="color-black">
              Jetzt einloggen.
            </router-link>.
          </p>
          <p class="feedbackmessage t-center pad-8 color-orange" v-if="!emailCheck && email.length > 0">
            Bitte überprüfe deine E-Mail-Adresse
          </p>
          <p class="feedbackmessage t-center pad-8 color-orange" v-if="!passwordCheck && password2.length > 0">
            Das Passwort ist nicht identisch oder kürzer als 8 Zeichen.
          </p>
          <div class="field simple pad-8">
            <input tabindex="4" v-model="agb" type="checkbox" required name="agb" id="agb">
            <label for="agb" style="display: inline-block;margin-left: 8px;vertical-align: top;">Ich stimme den <a href="https://www.easycrop.de/agb/" target="_blank">AGB</a> und den  <a href="https://www.easycrop.de/nutzungsbedingungen/" target="_blank">Nutzungsbedingungen</a> zu.</label>
          </div>
          <div class="field simple pad-8">
            <input tabindex="5" v-model="privacy" type="checkbox" required name="privacy" id="privacy">
            <label for="privacy" style="display: initial;margin-left: 8px;vertical-align: top;">
              Ich habe die  <a href="https://www.easycrop.de/datenschutz" target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen und stimme der Verarbeitung meiner Daten zu.
            </label>
            <p class="feedbackmessage t-center pad-8 color-black" v-if="!privacyCheck || !agbCheck">
              BItte bestätige unsere AGB, Nutzungsbedingungen und Datenschutzerklärung, um dich registrieren zu können.
            </p>
          </div>
        </div>
        <div class="pad-16" style="max-width: 320px; margin: 0 auto;">
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12">
              <button @click="$router.push({name: 'Register', params: {step: 2}})" class="button inverted bgcolor-black circular">
                Zurück
              </button>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <button @click="register" class="button bgcolor-petrol color-white circular" v-if="passwordCheck && emailCheck && privacyCheck && agbCheck">
                Los gehts!
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <transition name="modal">
    <div class="vueModal" v-if="avatarModal" ref="avatarModalRef" tabindex="0" @keydown.tab.prevent="tabAvatar" @keydown.enter.prevent="avatarModal = false; next = true;$router.push({name: 'Register', params: {step: 2}})">
      <div class="vueModalOuter" @click="avatarModal = false"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h4>Wähle deinen Avatar</h4>
        </div>
        <div id="SelectionPad">
          <div class="grid-12">
            <div class="col-sp-4 row-sp-12 pad-4 t-center" v-for="(avatar, index) in avatars" :key="index" @click="avatarIndex = index" :class="index == avatarIndex ? 'active' : ''">
              <img :src="'assets/images/avatars/' + avatars[index]" class="centered circular width-64 bgcolor-lightgrey is-square">
            </div>
          </div>
        </div>
        <div class="t-center pad-32">
          <input type="hidden" value="" name="avatar" id="avatar">
          <button class="button bgcolor-petrol circular color-white" @click="avatarModal = false; next = true;$router.push({name: 'Register', params: {step: 2}})">
            Weiter ➔
          </button>
        </div>
      </div>
    </div>
  </transition>
  <VueModal :show="status === 'registered'" approve="Zum Login" @close="$router.push('/login')" @approve="$router.push('/login')">
    <p class="t-center" id="completeRegister">
      Nur noch ein Schritt! <br>
      Bitte schaue in dein E-Mail-Postfach und bestätige deine E-Mail-Adresse.
    </p>
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";

const {configStore} = require("@/store/configStore");

export default {
  name: "Register",
  title: "Register",
  beforeRouteUpdate(to){
    this.$nextTick(() => {
      this.config.loading = false
    })
    if(to.params.step == '') this.$router.push('/')
    if((to.params.step == 2 && !this.next) || (to.params.step == 3 && !(this.firstname.length > 0 && this.lastname.length > 0))) this.$router.push('/register/1')
  },
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  setup(){
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      avatarModal: false,
      avatars: ['avatar-1.png', 'avatar-2.png', 'avatar-3.png'],
      next: false,
      avatarIndex:0,
      email: '',
      password: '',
      password2: '',
      position: '',
      company: '',
      firstname: '',
      lastname: '',
      status: '',
      country: -1,
      privacy: false,
      agb: false,
      countrys: [
        {iso: 'DE', name: 'Deutschland'},
        {iso: 'CH', name: 'Schweiz'},
        {iso: 'AT', name: 'Österreich'},
      ]
    }
  },
  watch:{
    step(){
      this.$nextTick(() => {
        if(this.step == 1) this.$refs.GetStarted.focus()
        else if(this.step == 2) this.$refs.firstnameinput.focus()
        else if(this.step == 3) this.$refs.email.focus()
      })
    }
  },
  computed:{
    passwordCheck(){
      return this.password.length > 7 && this.password === this.password2
    },
    emailCheck(){
      return this.email.match(/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g)
    },
    privacyCheck(){
      return this.privacy === true
    },
    agbCheck(){
      return this.agb === true
    }
  },
  mounted() {

    this.config.loading = false
    if(this.step == '') this.$router.push('/')
    if((this.step == 2 && !this.next) || (this.step == 3 && !(this.firstname.length > 0 && this.lastname.length > 0))) this.$router.push('/register/1')

    if(this.step == 1) this.$refs.GetStarted.focus()
    else if(this.step == 2) this.$refs.firstnameinput.focus()
    else if(this.step == 3) this.$refs.email.focus()
    this.checkStandaloneReg()
    /*
    window.addEventListener('keyup', event => {
      if (event.keyCode === 13) {
        if(this.step == 1)
          if(this.avatarModal == false){this.avatarModal = true;}
          else{this.avatarModal = false; this.next = true;this.$router.push({name: 'Register', params: {step: 2}});}
        if(this.step == 2){
          this.$refs.firstnameinput.focus()
          if(this.firstname.length > 0 ){
            this.$refs.lastnameinput.focus()
            if(this.lastname.length > 0){
              this.$router.push({name: 'Register', params: {step: 3}})
            }
          }
        }

      }
    })

    window.addEventListener('keyup', event => {
      if (event.keyCode === 9) {
        if(this.step == 1 && this.avatarModal == true){
          if(this.avatar == 'avatar-1.png'){this.avatar = 'avatar-2.png';}
          else{
            if(this.avatar == 'avatar-2.png'){this.avatar = 'avatar-3.png';}else{
              if(this.avatar == 'avatar-3.png'){this.avatar = 'avatar-1.png';}
            }
          }
        }

      }
    })*/
  },
  methods: {
    checkStandaloneReg(){
      if(this.$route.query.avatarSelection){
        console.log(this.$route.query.avatarSelection)
        this.avatarIndex = this.$route.query.avatarSelection;
        this.next = true;
        this.$router.push({name: 'Register', params: {step: 2}})
      }
    },
    tabAvatar(){
      this.avatarIndex = (this.avatarIndex >= this.avatars.length - 1) ? 0 : this.avatarIndex + 1
    },
    register(){
      const _this = this
      this.auth.register(this.email, this.firstname, this.lastname, this.password, this.avatars[this.avatarIndex], this.position, this.company, this.country)
          .then((response) => {
            console.log(response)
            if(response === 250){
              _this.status = 'registered'
            }else if(response === 251){
              _this.status = 'email'
            }else{
              _this.status = 'fail'
            }
          })
          .catch(e => {
            console.log(e)
            _this.status = 'fail'
          })
    },
    showPassword(){
      var showPasswordIcon = document.getElementById('showPasswordIcon');
      var hidePasswordIcon = document.getElementById('hidePasswordIcon');
      var registerPassword = document.getElementById('registerPassword');
      var registerPassword2 = document.getElementById('registerPassword2');
      if(registerPassword.type == 'password'){
        registerPassword.type = 'text';
        registerPassword2.type = 'text';
        showPasswordIcon.style.display = 'none';
        hidePasswordIcon.style.display = 'inline';
      }else{
        registerPassword.type = 'password';
        registerPassword2.type = 'password';
        showPasswordIcon.style.display = 'inline';
        hidePasswordIcon.style.display = 'none';
      }
    }
  }
}
</script>

<style scoped>
#showPassword{
  opacity: 0.6;
  cursor: pointer;
  width: 32px;
  margin-top: -28px;
  margin-left: 360px;
}

#hidePasswordIcon{
  display: none;
}
#GetStarted h2{
  color: rgba(0,0,0,0.8);
}
#SelectionPad .grid-12{
  max-width: 240px;
  margin: 0 auto;
  padding-bottom: 32px;
}
#SelectionPad img{
  border: 4px solid rgba(0,0,0,0.2);
  transition: 0.3s;
  opacity: 0.8;
}
#SelectionPad img:hover{
  border-color: rgba(0,0,0,0.4);
  opacity: 1;
}
#SelectionPad .active img{
  border-color: #ff8565;
  opacity: 1;
}
#Register{
  padding-top: 20vh;
}
#Register h2{
  color: rgba(0,0,0,0.8);
}
.feedbackmessage{
  font-size: 0.8em;
}
input[type="checkbox"]:focus {
  outline: 2px solid #135969;
}
</style>