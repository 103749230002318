<template>
  <div class="fullBar">
    <div class="arrowImageCon float-left" @click="number(-1)">
      <div class="arrowImage" :class="{arrowLeft: leftArrow}"/>
    </div>
    <div class="innerConBar" ref="inner">
      <div class="miniConBar" :style="{left: left + 'px', transition: transition ? 'all 1s ease' : 'none'}" ref="mini" @touchstart="touchStart" @touchmove.stop.prevent="touchMove" @touchend="touchEnd">
        <miniature v-for="(image, index) in images.files" :key="index" :index="index" @position="position" @saveFocus="$emit('saveFocus')"/>
      </div>
      <div class="fader" />
    </div>
    <div class="arrowImageCon float-right" @click="number(1)">
      <div class="arrowImage" :class="{arrowRight: rightArrow}"/>
    </div>
  </div>
</template>

<script>
import miniature from "@/components/miniature";
import {imageStore} from "@/store/imageStore";

export default {
  name: "SliderBar",
  components:{
    miniature
  },
  setup() {
    const images = imageStore()

    return {images}
  },
  data() {
    return {
      left: 40,
      offsetLeft: 0,
      offsetWidth: 0,
      transition: false,
      leftArrow: true,
      rightArrow: true,
      xTouch: 0,
      xTouchOffset: 0
    }
  },
  mounted() {
    window.onresize = () => {this.position()}
  },
  methods: {
    number(count){
      this.xTouch = 0
      if((!this.leftArrow && count < 0) || (!this.rightArrow && count > 0)) return

      this.offsetLeft += count < 0 ? - this.$refs.inner.offsetWidth + (this.offsetWidth + 20) * 2: this.$refs.inner.offsetWidth - (this.offsetWidth + 20) * 2;

      if(this.$refs.mini.offsetWidth + 80 < this.$refs.inner.offsetWidth){ //if all fit inside
        this.offsetLeft = - (this.$refs.inner.offsetWidth - this.$refs.mini.offsetWidth) / 2
        this.leftArrow = false
        this.rightArrow = false
      }else if(this.$refs.mini.offsetWidth < this.offsetLeft + this.$refs.inner.offsetWidth + 40){ //else if at end
        this.offsetLeft = - this.$refs.inner.offsetWidth + this.$refs.mini.offsetWidth + 40
        this.leftArrow = true
        this.rightArrow = false
      }else if(this.offsetLeft < 40){ // else if at beginning
        this.offsetLeft = - 40
        this.leftArrow = false
        this.rightArrow = true
      }else{ //else center them
        this.leftArrow = true
        this.rightArrow = true
      }
      this.left = - this.offsetLeft
    },
    position(left = this.offsetLeft, width = this.offsetWidth, force = true){
      if(force) this.xTouch = 0
      this.offsetLeft = left
      this.offsetWidth = width

      if(this.$refs.mini.offsetWidth + 80 < this.$refs.inner.offsetWidth){ //if all fit inside
        this.left = (this.$refs.inner.offsetWidth - this.$refs.mini.offsetWidth) / 2
        this.leftArrow = false
        this.rightArrow = false
      }else if(this.$refs.inner.offsetWidth / 2 > this.$refs.mini.offsetWidth - this.offsetLeft - this.offsetWidth / 2){ //else if at end
        this.left = this.$refs.inner.offsetWidth - this.$refs.mini.offsetWidth - 40
        this.leftArrow = true
        this.rightArrow = false
      }else if(this.$refs.inner.offsetWidth / 2 > this.offsetLeft + 40){ // else if at beginning
        this.left = 40
        this.leftArrow = false
        this.rightArrow = true
      }else{ //else center them
        if(!this.xTouch) this.left = +(this.$refs.inner.offsetWidth / 2) - this.offsetWidth / 2 - this.offsetLeft
        //this.left = +(this.$refs.inner.offsetWidth / 2) - this.offsetWidth / 2 - this.offsetLeft
        this.leftArrow = true
        this.rightArrow = true
      }

      this.offsetLeft = - this.left
      this.transition = true
    },
    touchStart(e){
      this.xTouch = e.touches[0].screenX
      this.xTouchOffset = this.offsetLeft
    },
    touchMove(e){
      this.transition = false
      this.left = - (this.xTouchOffset - (e.touches[0].screenX - this.xTouch))
      this.offsetLeft = - this.left
    },
    touchEnd(e){
      this.transition = true
      if(this.$refs.mini.offsetWidth + 80 < this.$refs.inner.offsetWidth){ //if all fit inside
        this.left = (this.$refs.inner.offsetWidth - this.$refs.mini.offsetWidth) / 2
        this.leftArrow = false
        this.rightArrow = false
      }else if(this.$refs.inner.offsetWidth / 2 > this.$refs.mini.offsetWidth - this.offsetLeft - this.offsetWidth / 2){ //else if at end
        this.left = this.$refs.inner.offsetWidth - this.$refs.mini.offsetWidth - 40
        this.leftArrow = true
        this.rightArrow = false
      }else if(this.$refs.inner.offsetWidth / 2 > this.offsetLeft + 40){ // else if at beginning
        this.left = 40
        this.leftArrow = false
        this.rightArrow = true
      }else{ //else center them
        //this.left = +(this.$refs.inner.offsetWidth / 2) - this.offsetWidth / 2 - this.offsetLeft
        this.leftArrow = true
        this.rightArrow = true
      }
      this.offsetLeft = - this.left
    }
  }
}
</script>

<style scoped>
.fullBar{
  width: 100%;
  height: 75px;
  position: relative;
}
.innerConBar{
  width: calc(100% - 80px);
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 40px;
}
.miniConBar{
  overflow:hidden;
  white-space: nowrap;
  position: absolute;
  left: 40px;
  top: 0px;
}
.fader{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(19,89,105,1) 0%, rgba(19,89,105,0) 10%, rgba(19,89,105,0) 90%, rgba(19,89,105,1) 100%);
}
.arrowImage{
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.arrowLeft{
  background-image: url("../../public/assets/images/pfeil-links-weiss.svg");
}
.arrowRight{
  background-image: url("../../public/assets/images/pfeil-rechts-weiss.svg");
}
.arrowImageCon{
  width: 50px;
  height: 50px;
  margin: 10px;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}
</style>