<template>
  <header class="display-Dektop">
    <div class="content-1400">
      <div class="grid-12">
        <div id="navbarLogo" class="col-sp-12 col-tb-3 row-sp-12">
          <a href="dashboard">
            <img src="/assets/images/logo-sticky.png" class="responsive-img width-150">
          </a>
        </div>
        <div class="col-sp-12 col-tb-9 row-sp-12 t-right pad-8">
          <ul v-if="this.$route.name !== 'RegisterStandalone'">
            <li>
              <a href="https://www.easycrop.de/features/">Features</a>
            </li>
            <li>
              <a href="https://www.easycrop.de/category/tutorials/">Tutorials</a>
            </li>
            <li>
              <a href="https://www.easycrop.de/faq/">FAQ</a>
            </li>
            <li>
              <a href="https://www.easycrop.de/blog/">Blog</a>
            </li>
            <li>
              <a href="/login" class="button bgcolor-orange color-white" style="color: white!important;">
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <header class="display-Mobile bgcolor-petrol color-white">
    <div class="content-1400">
      <div class="grid-12">
        <div class="col-sp-4 row-sp-12 pad-16">

        </div>
        <div class="col-sp-4 row-sp-12 t-center pad-16">
          <a href="/">
            <img src="/assets/images/logo-mobile.png" style="vertical-align: -16px;" class="responsive-img width-150">
          </a>
        </div>
        <div class="col-sp-4 row-sp-12 t-right pad-16">
          <div style="display:inline;vertical-align: -16px;" v-if="this.$route.name !== 'RegisterStandalone'">
            <img style="cursor: pointer;" src="/assets/images/menu.svg" class="width-48" @click="MobileMenuModal = true;" v-if="MobileMenuModal == false">
            <img style="cursor: pointer;" src="/assets/images/close.svg" class="width-48" @click="MobileMenuModal = false;" v-if="MobileMenuModal == true">
          </div>
        </div>
      </div>
    </div>
  </header>
  <transition v-if="MobileMenuModal == true" id="MobileMenu" class="display-Mobile bgcolor-petrol color-white" name="modal">
    <ul>
      <li>
        <a href="https://www.easycrop.de/features/"  @click="MobileMenuModal = false;">Features</a>
      </li>
      <li>
        <a href="https://www.easycrop.de/category/tutorials/"  @click="MobileMenuModal = false;">Tutorials</a>
      </li>
      <li>
        <a href="https://www.easycrop.de/faq/"  @click="MobileMenuModal = false;">FAQ</a>
      </li>
      <li>
        <a href="https://www.easycrop.de/blog/"  @click="MobileMenuModal = false;">Blog</a>
      </li>
      <li>
        <router-link to="/login" :style="[this.$route.name !== 'LoginStandalone' ? 'color: #ffffff' : '']" @click="MobileMenuModal = false;">Login</router-link>
      </li>
    </ul>
  </transition>

  <transition name="fade">
    <div id="pagetop" v-show="scY > 30" @click="toTop">
      <div class="progress-wrap active-progress">
        <svg id="icon-pfeil-oben" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.3 134.1" style="enable-background:new 0 0 236.3 134.1" xml:space="preserve">
        <path d="M18.3 134.1c-4.8 0-9.5-1.8-13.1-5.5-7.1-7.2-6.9-18.8.3-25.9l99.9-97.5c7.1-6.9 18.5-6.9 25.6 0l99.8 97.5c7.2 7.1 7.4 18.6.3 25.9-7.1 7.2-18.6 7.4-25.9.3l-87-85-87.1 85c-3.6 3.5-8.2 5.2-12.8 5.2z"></path>
      </svg>
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" :style="'transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: '+dasharray+', '+dasharray+'; stroke-dashoffset:'+offset+';'"></path>
        </svg>
      </div>
    </div>
  </transition>
</template>
<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "StandaloneNavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      MobileMenuModal: false,
      scTimer: 0,
      scY: 0,
      offset: 0,
      dasharray: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      var body = document.body, html = document.documentElement;
      var height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
      this.dasharray = height;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        this.offset = height - window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
}
</script>

<style scoped>
header{
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  left: 0;
  z-index: 99;
}
#navbarLogo{
  margin-top: 16px;
  margin-left: 16px;
}
header ul{
  padding: 0;
  margin: 10px 0 0 0;
  display: inline-block;
}
header ul li{
  padding: 0;
  margin: 0;
  display: inline;
}
header ul li a{
  font-size: 17px;
  text-decoration: none;
  margin-left: 32px;
  color: #135969;
  transition: color .35s;
}
@media (hover: hover) {
  header ul > li > a:hover {
    color: #fc8465;
  }
}
header ul li button{
  margin-left: 32px;
  margin-right: 32px;
}
#TopNavDropDownBtn{
  display: inline-block;
  vertical-align: -8px;
}
#MobileMenu{
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 99;
}
#MobileMenu li a{
  padding: 16px;
  display: block;
  color: rgba(255,255,255,0.6);
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
}
#MobileMenu li a:hover{
  color: rgba(255,255,255,1);
}
.progress-wrap {
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 46px;
  height: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px rgb(0 0 0 / 10%);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: opacity .2s,transform .2s;
}
.progress-wrap svg path {
  fill: #FFF;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap svg.progress-circle path {
  stroke: #86DEC8;
  stroke-width: 5;
  transition: all .2s linear;
}
.progress-wrap #icon-pfeil-oben {
  z-index: 10;
  width: 20px;
  height: 11px;
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%,-48%);
  margin-right: 0;
  cursor: pointer;
  display: block;
}
.progress-wrap svg {
  overflow: visible;
}
.progress-wrap #icon-pfeil-oben path {
  fill: #86DEC8;
  transition: all .2s linear;
}
.progress-wrap svg.progress-circle path {
  stroke: #86DEC8;
  stroke-width: 5;
  transition: all .2s linear;
}
</style>