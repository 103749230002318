<template>
  <transition name="modal">
    <div class="vueModal" v-if="show">
      <div class="vueModalOuter" @click="$emit('close')"/>
      <div class="vueModalInner">
        <div class="close" @click="$emit('close')">
          <img src="assets/images/kreuz-petrol.svg" style="height: 16px;">
        </div>
        <div class="pad-8"></div>
        <div class="pad-8">
          <slot />
        </div>
        <div class="pad-8 t-center">
          <button class="button circular" style="margin-left: 8px; margin-bottom: 8px;" @click="$emit('cancel')"  v-if="cancel" :disabled="cancelDisabled" :class="[{disabled: cancelDisabled}, cancelClass]">
            {{ cancel }}
          </button>
          <button class="button circular" style="margin-left: 8px; margin-bottom: 8px;" @click="$emit('approve')"  v-if="approve" :disabled="approveDisabled" :class="[{disabled: approveDisabled}, approveClass]">
            {{ approve }}
          </button>
          <button class="button circular" style="margin-left: 8px; margin-bottom: 8px;" @click="$emit('option1')"  v-if="option1" :disabled="option1Disabled" :class="[{disabled: option1Disabled}, option1Class]">
            {{ option1 }}
          </button>
          <button class="button circular" style="margin-left: 8px; margin-bottom: 8px;" @click="$emit('option2')"  v-if="option2" :disabled="option2Disabled" :class="[{disabled: option2Disabled}, option2Class]">
            {{ option2 }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    cancel: {
      type: String,
      required: false
    },
    cancelDisabled: {
      type: Boolean,
      required: false
    },
    cancelClass: {
      type: String,
      required: false,
      default: 'bgcolor-mint color-black'
    },
    approve: {
      type: String,
      required: false
    },
    approveDisabled: {
      type: Boolean,
      required: false
    },
    approveClass: {
      type: String,
      required: false,
      default: 'bgcolor-mint color-black'
    },
    option1: {
      type: String,
      required: false
    },
    option1Disabled: {
      type: Boolean,
      required: false
    },
    option1Class: {
      type: String,
      required: false,
      default: 'bgcolor-mint color-black'
    },
    option2: {
      type: String,
      required: false
    },
    option2Disabled: {
      type: Boolean,
      required: false
    },
    option2Class: {
      type: String,
      required: false,
      default: 'bgcolor-mint color-black'
    },
  }
}
</script>

<style scoped>
.vueModal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px) brightness(0.5);
}
.vueModalOuter{
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
}
.vueModalInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.button{
  text-align: center;
}
.close{
  position: absolute;
  top: 10px;
  right: 10px;
}
.disabled{
  background-color: grey !important;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>