<template>
<div class="miniCon" ref="con">
  <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MiniatureInfo",
  mounted() {
    const _this = this
  },
  methods: {
  }
}
</script>

<style scoped>
.miniCon{
  height: 100%;
  /*width: 100%;*/
  width: 60vw;
  border-radius: 5px;
  margin: 10px;
  overflow: hidden;
  position: relative;
  background-color: #def7f1;
  display: inline-block;
}
img{
  position: absolute;
  max-width: 100% !important;
  max-height: 100% !important;;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.active{
  box-shadow: 0 0 0 4px #fe8b6e
}
</style>